import React, { useEffect, useState } from "react";
import "../scss/index.scss";
import { CSSTransition } from "react-transition-group";
import { useSelector, useDispatch } from "react-redux";
import {
  getWithdrawHistory,
  getActiveWallet,
  getVolumes,
  withdrawFees,
  apiRequestLimits,
  getQuotes, // Import the getQuotes action
} from "../store/actions/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import DataLoader from "../components/globalView/DataLoader";
import useWindowSize from "../utils/useWindowSize";
import TableDataWithdraws from "../components/tables/TableDataWithdraws";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { changePage } from "../store/actions";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import LazyLoad from 'react-lazyload';

const Balances = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sized = useWindowSize();

  const [tableOpen, setTableOpen] = useState("");
  const [timer, setTimer] = useState(60);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationData, setCalculationData] = useState(null);
  const [checkPending, setCheckPending] = useState(false);

  const withdrawHistory = useSelector(
    (state) => state.userReducer.withdrawHistory
  );
  const volumes = useSelector((state) => state.userReducer.volumes);
  const activeWallet = useSelector((state) => state.userReducer.activeWallet);
  const withdrawFeesMsg = useSelector(
    (state) => state.userReducer.withdrawFeesMsg
  );
  const isLoading = useSelector((state) => state.userReducer.loading);
  const customLoading = useSelector((state) => state.userReducer.customLoader);

  useEffect(() => {
    dispatch(getActiveWallet());
    dispatch(getVolumes());
    dispatch(apiRequestLimits());
    fetchWithdrawHistory();
  }, [dispatch]);

  useEffect(() => {
    let interval;
    if (!isCalculating && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      handleCalculateClick(); // Re-trigger the getQuotes function
    }
    return () => clearInterval(interval);
  }, [isCalculating, timer]);

  useEffect(() => {
    const sumBtcVolume = parseFloat(volumes?.balances?.rune?.balance || 0) +
      parseFloat(volumes?.balances?.usdc?.balance || 0) +
      parseFloat(volumes?.balances?.cacao?.balance || 0);

    if (sumBtcVolume >= 100) {
      handleCalculateClick();
    }
  }, [volumes]);

  useEffect(() => {
    if (withdrawHistory?.length > 0 && withdrawHistory[0].status === 'pending') {
      setCheckPending(true);
    } else if (withdrawHistory?.length > 0 && withdrawHistory[0].status === 'done') {
      dispatch(getVolumes());
    } else {
      setCheckPending(false);
    }
  }, [withdrawHistory]);

  useEffect(() => {
    if (checkPending) {
      const interval = setInterval(() => {
        fetchWithdrawHistory();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [checkPending]);

  const fetchWithdrawHistory = async () => {
    try {
      const response = await dispatch(getWithdrawHistory());
      if (response?.length > 0 && response[0].status !== 'pending') {
        setCheckPending(false);
      }
    } catch (error) {
      console.error("Failed to fetch withdraw history", error);
    }
  };

  const handleCalculateClick = () => {
    setIsCalculating(true);

    const sumBalanceInUsd = parseFloat(volumes?.balances?.rune?.balanceInUsd || 0) +
      parseFloat(volumes?.balances?.usdc?.balanceInUsd || 0) +
      parseFloat(volumes?.balances?.cacao?.balanceInUsd || 0);

    if (sumBalanceInUsd > 100) {
      dispatch(getQuotes(sumBalanceInUsd))
        .then((response) => {
          setCalculationData(response); // Store the response data
          setIsCalculating(false);
          setTimer(60); // Start the timer after the response
        })
        .catch((err) => {
          console.error("Failed to fetch quotes", err);
          setIsCalculating(false);
        });
    } else {
      setIsCalculating(false);
    }
  };

  const currentDiv = (id) => {
    setTableOpen(id);
  };

  const requestWithdrawFees = async () => {
    if (calculationData && calculationData?.usdc && calculationData?.usdc.buyAmount) {
      try {
        const response = await dispatch(withdrawFees(calculationData?.usdc.buyAmount));
        if (response === "Request Sent!, Your withdraw request is pending and it will be viewed by our team to be proceeded") {
          fetchWithdrawHistory();
          setCheckPending(true);
          // Trigger the fetchWithdrawHistory interval

        }
        // Display the response message
        console.log(response);
      } catch (err) {
        console.error("Failed to request withdraw fees", err);
      }
    } else {
      console.error("Buy amount is not available");
    }
  };

  const handleWithdrawWalletRedirect = () => {
    dispatch(changePage("edit-wallet"));
    navigate("/edit-wallet");
  };

  const sumBtcVolume = parseFloat(volumes?.balances?.rune?.balance || 0) +
    parseFloat(volumes?.balances?.usdc?.balance || 0) +
    parseFloat(volumes?.balances?.cacao?.balance || 0);

  return (
    <>
      <Helmet>
        <title>Balances | Instaswap Partners</title>
      </Helmet>
      <CSSTransition
        timeout={{
          enter: 8500,
          exit: 500,
        }}
        classNames="item"
        key="transactions"
      >
        <div className="px-0 main-area extraMn">
          <>
            {/* The Top Section - Withdraw History and Button */}

            <div className="flex md:flex-row flex-col justify-between items-center px-2" style={{ marginBottom: '15px' }}>
              <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                <h1 className="text-4xl text-white w-full text-left">Balances</h1>
              </div>
            </div>

            <div className="">
              <div
                className="text-md text-white p-3 rounded-md md:mt-0 mt-4"
                style={sized.width > 768 ? ({
                  background: "#2d2d2d",
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '10px',
                }) : ({
                  background: "#2d2d2d",
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginBottom: '50px',
                  placeContent: 'center space-between'
                })}
              >
                <div style={{ width: '100%', padding: '10px', background: '#1D1D1D', borderRadius: '10px' }}>
                  <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }}>
                    {volumes?.balances?.rune?.icon ? (
                      <LazyLoad height={45} offset={100}>
                        <img src={volumes?.balances?.rune?.icon} width="45px" height="45px" alt="RUNE" />
                      </LazyLoad>
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin style={{ width: '45px', height: '45px' }} />
                    )}
                  </div>
                  <p className="py-1 text-center">
                    Balance in <span className="font-semibold">RUNE</span> :{" "}
                    <span style={{ color: "#24ae7a" }}>{volumes?.balances?.rune?.balance.toFixed(1)}</span>
                    <span style={{ color: "darkgrey", marginLeft: "5px", fontSize: "12px" }}>({volumes?.balances?.rune?.balanceInUsd.toFixed(2)}$)</span>
                  </p>
                </div>
                <div style={{ width: '100%', padding: '10px', background: '#1D1D1D', borderRadius: '10px' }}>
                  <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }}>
                    {volumes?.balances?.usdc?.icon ? (
                      <LazyLoad height={45} offset={100}>
                        <img src={volumes?.balances?.usdc?.icon} width="45px" height="45px" alt="USDC" />
                      </LazyLoad>
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin style={{ width: '45px', height: '45px' }} />
                    )}
                  </div>
                  <p className="py-1 text-center">
                    Balance in <span className="font-semibold">USDC</span> :{" "}
                    <span style={{ color: "#24ae7a" }}>{volumes?.balances?.usdc?.balance.toFixed(1)}</span>
                    <span style={{ color: "darkgrey", marginLeft: "5px", fontSize: "12px" }}>({volumes?.balances?.usdc?.balanceInUsd.toFixed(2)}$)</span>
                  </p>
                </div>
                <div style={{ width: '100%', padding: '10px', background: '#1D1D1D', borderRadius: '10px' }}>
                  <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }}>
                    {volumes?.balances?.cacao?.icon ? (
                      <LazyLoad height={45} offset={100}>
                        <img src={volumes?.balances?.cacao?.icon} width="45px" height="45px" alt="Cacao" />
                      </LazyLoad>
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin style={{ width: '45px', height: '45px' }} />
                    )}
                  </div>
                  <p className="py-1 text-center">
                    Balance in <span className="font-semibold">Cacao</span> :{" "}
                    <span style={{ color: "#24ae7a" }}>{volumes?.balances?.cacao?.balance.toFixed(1)}</span>
                    <span style={{ color: "darkgrey", marginLeft: "5px", fontSize: "12px" }}>({volumes?.balances?.cacao?.balanceInUsd.toFixed(2)}$)</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="">
              <div
                className="text-md text-white p-3 rounded-md md:mt-0 mt-4"
                style={sized.width > 768 ? ({
                  background: "#2d2d2d",
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: '10px',
                  paddingLeft: "15px",
                  paddingRight: "15px"
                }) : ({
                  background: "#2d2d2d",
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: '50px',
                  placeContent: 'center space-between'
                }
                )}
              >
                <div style={{ width: '100%' }}>
                  <div className="flex flex-col md:flex-row items-center">
                    <span>You will receive approx:</span>
                    {isCalculating ? (
                      <SkeletonTheme
                        color="#1d1d1d"
                        highlightColor="#2d2d2d"
                        borderRadius="4px"
                        duration={1.2}
                        width="187px"
                        height={20}
                      >
                        <Skeleton style={{ marginRight: '10px', marginLeft: '5px' }} />
                      </SkeletonTheme>
                    ) : (
                      <>
                        <img
                          src="https://web.instaswap.com/images/products/small-389ee44692b3dc76b3a1b32d537b260a.png"
                          width="25px"
                          height="25px"
                          alt="BTC"
                          style={{ marginLeft: '10px', marginRight: '10px' }}
                        />
                        {calculationData?.usdc?.buyAmount || "N/A"} <span style={{ color: "#24ae7a", marginRight: "10px", marginLeft: "5px" }}>(+{calculationData?.usdc?.buyAmountUSD.toFixed(0) || "N/A"}$)</span>
                      </>
                    )}
                    on address: {" "}
                    <div className="font-semibold text-white p-2 flex items-center xl:w-1/4 md:w-1/3 w-28" onClick={handleWithdrawWalletRedirect} style={{ cursor: "pointer" }}>
                      &nbsp;
                      <span className="hover:text-green-600">
                        {activeWallet ? (
                          `${activeWallet.slice(0, 4).toUpperCase()}...${activeWallet.slice(-4).toUpperCase()}`
                        ) : (
                          <span onClick={handleWithdrawWalletRedirect} style={{ cursor: 'pointer', color: '#24ae7a' }}>
                            + Add BTC address
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{
                  width: '120px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                  <div>
                    <button
                      className="text-md text-white p-2 rounded-md"
                      style={{ cursor: "pointer", backgroundColor: "#24ae7a" }}
                      onClick={() => { handleCalculateClick(); }} // Ensure this is correctly bound
                      disabled={isCalculating || sumBtcVolume < 100}
                    >
                      {isCalculating ? (
                        <div className="flex items-center">
                          <FontAwesomeIcon color="white" icon={faSpinner} spin />
                          <span className="ml-2">Converting</span>
                        </div>
                      ) : (
                        timer > 0 ? (
                          <span>{timer}s</span>
                        ) : (
                          <span>Convert</span>
                        )
                      )}
                    </button>
                  </div>
                  <div>
                    {calculationData && activeWallet && (
                      <button
                        className="text-md text-white p-2 rounded-md ml-2"
                        style={
                          parseFloat(volumes?.balances?.rune?.balance) === 0 &&
                            parseFloat(volumes?.balances?.usdc?.balance) === 0 &&
                            parseFloat(volumes?.balances?.cacao?.balance) === 0
                            ? {
                              cursor: "not-allowed",
                              backgroundColor: "#24ae7a",
                            }
                            : { cursor: "pointer", backgroundColor: "#24ae7a" }
                        }
                        onClick={requestWithdrawFees}
                        disabled={
                          parseFloat(volumes?.balances?.rune?.balance) > 0 ||
                            parseFloat(volumes?.balances?.usdc?.balance) > 0 ||
                            parseFloat(volumes?.balances?.cacao?.balance) > 0 ||
                            sumBtcVolume < 100
                            ? false
                            : true
                        }
                      >
                        {isLoading ? (
                          <>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px", width: "120px", paddingLeft: "20px" }}>
                              <span>Withdraw</span>
                              <FontAwesomeIcon
                                color="white"
                                icon={faSpinner}
                                spin
                              />
                            </div>

                          </>
                        ) : (
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px", width: "120px", paddingLeft: "20px" }}>
                            Withdraw
                          </div>
                        )}
                      </button>
                    )}
                  </div>
                </div>
                {sumBtcVolume < 100 && (
                  <div className="text-center text-white-500" style={{ position: "absolute", height: "50px", backgroundColor: "#2a2a2aeb", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-15px" }}>
                    You will be able to withdraw after your total balance is at least 100$ worth of BTC
                  </div>
                )}
              </div>
            </div>


            {withdrawFeesMsg && (
              <div style={{ width: '100%', position: "absolute" }}>
                <div className="flex flex-col md:flex-row items-center">
                  <div className="text-md text-white p-3 rounded-md" style={{ background: "#2d2d2d", width: "100%" }}>
                    {withdrawFeesMsg}
                  </div>
                </div>
              </div>
            )}


            <div className="flex md:flex-row flex-col justify-between items-center px-2" style={{ marginTop: '100px' }}>
              <div className="flex flex-col xl:w-4/12 md:w-6/12 w-full">
                <h1 className="text-4xl text-white">Withdraw History</h1>
              </div>
            </div>

            {/* Table Section - Conditionally Display Withdraw History or No Data Message */}
            <div className="mx-auto px-2 mt-4">
              {withdrawHistory && withdrawHistory.length > 0 ? (
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden" style={{ minHeight: "calc(40vh)", borderRadius: "10px" }}>
                  <table
                    className="min-w-full leading-normal relative"
                    style={
                      customLoading === true ? { height: "323px" } : null
                    }
                  >
                    {sized.width > 768 ? (
                      <thead>
                        <tr
                          style={{
                            background: "#2d2d2d",
                            borderBottom: "2px solid #1D1D1D",
                          }}
                        >
                          <th className="md:px-2 px-5 py-3 text-left text-xs text-gray-500  tracking-wider">
                            ID
                          </th>
                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Withdraw Amount
                          </th>

                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Withdraw Wallet
                          </th>
                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Date
                          </th>
                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Status
                          </th>
                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Profits
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr
                          style={{
                            background: "#2d2d2d",
                            borderBottom: "2px solid #1D1D1D",
                          }}
                        >
                          <th className="md:px-2 px-5 py-3 text-left text-xs  text-gray-500  tracking-wider">
                            Withdraw History details
                          </th>
                        </tr>
                      </thead>
                    )}

                    {withdrawHistory &&
                      withdrawHistory.length > 0 &&
                      withdrawHistory.map((item) => (
                        <TableDataWithdraws
                          key={item.id}
                          item={item}
                          tableOpen={tableOpen}
                          currentDiv={currentDiv}
                        />
                      ))}
                  </table>
                </div>
              ) : (
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden box empty-table" style={{ height: "calc(40vh)", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h5 className="text-white text-center p-4">No Withdrawals Yet</h5>
                </div>
              )}
            </div>
          </>
        </div>
      </CSSTransition >
    </>
  );
};

export default Balances;
