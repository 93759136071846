import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../scss/index.scss";
import { apiRequestLimits, updateBps } from "../store/actions";
import DataLoader from "../components/globalView/DataLoader";
import { Helmet } from "react-helmet";
import btcImage from "../images/btc.png";
import runeImage from "../images/rune.jpg";
import moneyTree from "../images/money-tree.webp";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const FeeManagement = () => {
    const dispatch = useDispatch();
    const [volume, setVolume] = useState(0);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showUpdateDiv, setShowUpdateDiv] = useState(false);

    // Get loading status and bps value from the Redux store
    const isLoading = useSelector((state) => state.userReducer.loading);
    const bps = useSelector((state) => state.userReducer.user.response.bps);

    // Set the initial volume to bps from Redux store when the component loads
    useEffect(() => {
        if (bps !== undefined) {
            setVolume(bps);
        }
    }, [bps, dispatch]);

    useEffect(() => {
        dispatch(apiRequestLimits());
    }, [dispatch]);

    const handleSliderChange = (value) => {
        setVolume(value);
    };

    const handleSubmit = () => {
        dispatch(updateBps(volume));
        setSuccessMessage(true);
        setTimeout(() => {
            setSuccessMessage(false);
            setShowUpdateDiv(false);
        }, 1500);
    };

    return (
        <>
            <Helmet>
                <title>Fee Management | Instaswap Partners</title>
            </Helmet>
            {isLoading ? (
                <DataLoader />
            ) : (
                <div className="px-0 main-area extraMn">
                    <div className="flex md:flex-row flex-col justify-between items-center px-2">
                        <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                            <h1 className="text-4xl w-full text-left text-white">Fee Management</h1>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-start h-2/4 mt-20">
                        {!showUpdateDiv && (
                            <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
                                <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                                    <div className="image__container" style={{ marginTop: '120px', marginBottom: '130px', marginLeft: '1px', transform: 'scale(4)' }}>
                                        <div style={{ backgroundImage: `url(${moneyTree})`, backgroundSize: '150%', backgroundRepeat: 'no-repeat', backgroundPosition: '10px -44px', width: '100%', height: '100%', opacity: '0.5', position: 'absolute' }}></div>
                                        <div className="image__container__padding" style={{ backgroundColor: '#2d2d2dc7', position: 'relative', zIndex: '1', borderRadius: '6px' }}>
                                            <div style={{ width: '40px', position: 'relative', top: '-7px', left: '-7px' }}>
                                                <img style={{ width: '150px' }} src={btcImage} alt=""></img>
                                            </div>
                                            <div style={{ position: 'absolute', zIndex: '2', top: '9px', backgroundColor: '#2d2d2d', fontSize: '6px', textAlign: 'center', width: '70%', left: '7px', borderRadius: '3px', padding: '3px 0px 0px 0px' }}>
                                                <h2 className="text-gray-200"><div>Basis Points:</div> <b style={{ color: '#25b07b', fontSize: '15px' }}>{volume}</b></h2>
                                            </div>
                                            <div style={{ width: '40px', position: 'relative', top: '-20px', left: '20px' }}>
                                                <img style={{ width: '150px', borderRadius: '100%' }} src={runeImage} alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {bps !== undefined && (
                                    <div>
                                        <Slider
                                            min={0}
                                            max={500}
                                            value={volume}
                                            onChange={handleSliderChange}
                                            trackStyle={{ backgroundColor: '#25b07b', height: 10 }}
                                            handleStyle={{
                                                borderColor: '#25b07b',
                                                height: 28,
                                                width: 28,
                                                marginLeft: -14,
                                                marginTop: -9,
                                                backgroundColor: '#2d2d2d',
                                            }}
                                            railStyle={{ backgroundColor: '#1d1d1d', height: 10 }}
                                        />
                                        <div style={{ color: 'grey', fontStyle: 'italic' }}>
                                            <p>Notice to Our Partners:</p>
                                            <p>Select the basis points (bps)
                                                for transaction fees here.
                                                Please note that 50% of the chosen bps will be allocated to us as part of our agreement.</p>
                                        </div>
                                    </div>
                                )}

                                <button
                                    className="w-full p-2 text-gray-200 rounded-md mt-6"
                                    style={{ background: "#23ac79" }}
                                    onClick={handleSubmit}
                                >
                                    Save bps
                                </button>
                                {successMessage && (
                                    <div className="text-green-500 bg-gray-200 w-full text-center p-2 mt-2">
                                        Fee Basis Points Updated
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default FeeManagement;
