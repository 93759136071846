import React, { useState, useEffect } from "react";
import "../../scss/index.scss";
import useWindowSize from "../../utils/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import MiddleEllipsis from "react-middle-ellipsis";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TableDataTransactions = ({ item, currentDiv, tableOpen, openModal }) => {
  const sized = useWindowSize();

  const [openDiv, setOpenDiv] = useState(false);
  const [copiedDeposit, setCopiedDeposit] = useState(false);
  const [copiedWithdraw, setCopiedWithdraw] = useState(false);

  const openTable = () => {
    if (tableOpen === "") {
      setOpenDiv(true);
      currentDiv(item.id, openDiv);
    } else if (tableOpen === item.id) {
      setOpenDiv(!openDiv);
    } else if (tableOpen !== "" && tableOpen !== item.id) {
      setOpenDiv(true);
      currentDiv(item.id);
    }
  };

  // CHANGE DEPOSIT COPY STATUS AFTER 5 SECS
  useEffect(() => {
    if (copiedDeposit) {
      setTimeout(() => {
        setCopiedDeposit(false);
      }, 2500);
    }
  }, [copiedDeposit]);

  // CHANGE WITHDRAW COPY STATUS AFTER 5 SECS
  useEffect(() => {
    if (copiedWithdraw) {
      setTimeout(() => {
        setCopiedWithdraw(false);
      }, 2500);
    }
  }, [copiedWithdraw]);

  return (
    <tbody>
      {sized.width > 1024 ? (
        <tr style={{ background: "#292929", color: "white" }}>
          <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d" }}
          >
            <p className="" style={{ color: "white" }}>
              #{item.id}
            </p>
          </td>
          <td
            className={`px-2 md:px-5 md:py-2 py-5
             text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d" }}

          >
            <div
              className={`flex items-center
            `}
              style={{ minWidth: "220px" }}
            >
              <div className="image__container" style={{ marginLeft: "1px" }}>
                <div className="image__container__padding">
                  <div className="image__container__width">
                    <div
                      className="image__container__giveCoin"
                      style={{
                        borderRadius: `100%`,
                        backgroundImage: `url(${item.givenCoinImage.small})`,
                        minWidth: "100px",
                        minHeight: "100px",
                        backgroundColor: "#1d1d1d"
                      }}
                    ></div>
                  </div>
                  <div className="image__container__right">
                    <div
                      className="image__container__img"
                      style={{
                        borderRadius: `100%`,
                        backgroundImage: `url(${item.getCoinImage.small})`,
                        minWidth: "100px",
                        minHeight: "100px",
                        backgroundColor: "#1d1d1d"
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="ml-3">
                <p className="" style={{ color: "white" }}>
                  <span className="font-semibold">{item.givenCoin}</span> to{" "}
                  <span className="font-semibold">{item.getCoin}</span>
                </p>
              </div>
            </div>
          </td>
          <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d", minWidth: "210px" }}

          >
            <div className="flex items-center justify-start gap-2">
              <p className="font-bold  " style={{ color: "white" }}>
                {item.depositAmount.replace(
                  /^0+(?!\.)|(?:\.|(\..*?))0+$/gm,
                  "$1"
                )}
              </p>
              <img
                style={{
                  borderRadius: `100%`,
                }}
                src={item.givenCoinImage.small}
                alt="giveCoin-img"
                width="20px"
                height="20px"
              />
            </div>
          </td>

          <td
            className={`md:px-2 px-5 md:py-2 py-5 text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d", minWidth: "210px" }}

          >
            {item.orderState === "Deposit Not Completed" || item.orderState === "Refund" || parseFloat(item.profit) === 0 ? (
              <div className="font-bold" style={{ color: "#808080" }}>
                No Profit
              </div>
            ) : (
              <div className="font-bold flex items-center" style={{ color: "#808080" }}>
                <span className="text-white font-semibold">
                  {parseFloat(item.profit).toFixed(8)}
                </span>
                &nbsp;
                <img
                  style={{ borderRadius: `100%` }}
                  src={item.profitImageCurrency}
                  alt="getCoin-img"
                  width="20px"
                  height="20px"
                />
              </div>
            )}
          </td>
          <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative `}
            style={{ borderBottom: "1px solid #1d1d1d" }}

          >
            <p className="relative inline-block py-1 font-semibold">
              {item.orderState === "Deposit Not Completed" ? (
                <span className="btn-error text-xs">Failed</span>
              ) : item.orderState === "Completed" ? (
                <span className="btn-success text-xs">{item.orderState}</span>
              ) : item.orderState === "Refunded" ? (
                <span className="btn-warning text-xs">{item.orderState}</span>
              ) : (
                <span className="btn-pending text-xs">{item.orderState}</span>
              )}
            </p>
          </td>

          <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d" }}

          >
            {item.txnHash ? (
              <CopyToClipboard
                text={item.txnHash}
                onCopy={() => setCopiedDeposit(true)}
              >
                {item.txnHash ? (
                  <div
                    className={`${!copiedDeposit ? "w-1/4" : "w-full"
                      } flex items-center cursor-pointer`}
                  >
                    {!copiedDeposit ? (
                      <MiddleEllipsis>
                        <span className="font-bold">
                          {item.txnHash}
                        </span>
                      </MiddleEllipsis>
                    ) : (
                      <span className="text-green-500 font-bold">Copied!</span>
                    )}
                    <FontAwesomeIcon
                      color="#0E7C65"
                      icon={faCopy}
                      className="ml-3 z-20"
                      size="lg"
                    />
                  </div>
                ) : (
                  "-"
                )}
              </CopyToClipboard>
            ) : (
              "-"
            )}
          </td>
          {/* <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d" }}

          >
            <div className="flex items-center">
              <p className=" text-white font-semibold">{item.clientEmail}</p>
              {item.clientEmail === "" && (
                <FontAwesomeIcon
                  color="#0E7C65"
                  icon={faEdit}
                  onClick={() => openModal(true)}
                  className="ml-3 z-20 cursor-pointer"
                  size="2x"
                />
              )}
            </div>
          </td> */}
          <td
            className={`md:px-2 px-5 md:py-2 py-5  text-sm relative`}
            style={{ borderBottom: "1px solid #1d1d1d", minWidth: "210px" }}

          >
            <p className="font-bold  " style={{ color: "#808080" }}>
              {item.dateTimeCreated}
            </p>
          </td>
        </tr>
      ) : (
        <>
          <tr style={{ background: "#292929", color: "white" }}>
            <td
              className={`md:px-5 md:py-2 py-5
            border-b
          border-gray-200 text-sm relative`}
            >
              <div
                className={`flex items-center justify-around ${openDiv && tableOpen === item.id && `table__data__mb60`
                  }`}
                onClick={openTable}
              >
                <div className="image__container" style={{ marginLeft: "0px" }}>
                  <div className="image__container__padding">
                    <div className="image__container__width">
                      <div
                        className="image__container__giveCoin"
                        style={{
                          borderRadius: `100%`,
                          backgroundImage: `url(${item.givenCoinImage.small})`,
                        }}
                      ></div>
                    </div>
                    <div className="image__container__right">
                      <div
                        className="image__container__img"
                        style={{
                          borderRadius: `100%`,
                          backgroundImage: `url(${item.getCoinImage.small})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <p className="" style={{ color: "white" }}>
                    <span className="font-semibold">{item.givenCoin}</span> to{" "}
                    <span className="font-semibold">{item.getCoin}</span>
                  </p>
                  <p>
                    {item.orderState === "Deposit Not Completed" ? (
                      <span className="btn-error text-xs">
                        Failed
                      </span>
                    ) : item.orderState === "Refunded" ? (
                      <span className="btn-warning text-xs">
                        {item.orderState}
                      </span>
                    ) : item.orderState === "Completed" ? (
                      <span className="btn-success text-xs">
                        {item.orderState}
                      </span>
                    ) : (
                      <span className="btn-pending text-xs">
                        {item.orderState}
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    color="white"
                    icon={!openDiv ? faChevronDown : faChevronUp}
                    className="ml-2"
                  />
                </div>
              </div>

              {openDiv && tableOpen === item.id && (
                <div className="table__data__mobile">
                  <div
                    className={`flex mt-2 items-center expand absolute`}
                    style={{ marginLeft: "15px" }}
                  >
                    {" "}
                    <p className="" style={{ color: "white" }}>
                      ID: <span className="font-semibold">#{item.id}</span>
                    </p>
                  </div>
                  <div
                    className={`flex mt-2 items-center expand absolute`}
                    style={{ marginLeft: "15px", height: "78px" }}
                  >
                    <div className="flex items-center justify-start">
                      Amount: &nbsp;
                      <span className="font-bold">
                        {item.depositAmount.replace(
                          /^0+(?!\.)|(?:\.|(\..*?))0+$/gm,
                          "$1"
                        )}
                      </span>{" "}
                      &nbsp;{" "}
                      <img
                        src={item.givenCoinImage.small}
                        alt="giveCoin-img"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </div>
                  <div
                    className=" flex mt-2 items-center expand absolute"
                    style={{ marginLeft: "15px", height: "145px" }}
                  >
                    <p>Profit:</p>&nbsp;
                    {item.orderState === "Deposit Not Completed" || item.orderState === "Refund" || parseFloat(item.profit) === 0 ? (
                      <span className="text-white font-semibold">No Profit</span>
                    ) : (
                      <>
                        <span className="text-white font-semibold">
                          {parseFloat(item.profit).toFixed(8)}
                        </span>
                        &nbsp;
                        <img
                          src={item.profitImageCurrency}
                          alt="getCoin-img"
                          width="20px"
                          height="20px"
                        />
                      </>
                    )}
                  </div>

                  <div
                    className={`flex mt-2 items-center gap-4 absolute w-full`}
                    style={{ marginLeft: "15px", height: "210px" }}
                  >
                    <p>Txn Hash:</p>
                    {item.txnHash ? (
                      <CopyToClipboard
                        text={item.txnHash}
                        onCopy={() => setCopiedDeposit(true)}
                      >
                        {item.txnHash ? (
                          <div
                            className={`w-2/4 flex items-center cursor-pointer z-50`}
                          >
                            {!copiedDeposit ? (
                              <MiddleEllipsis>
                                <span className="font-bold">
                                  {item.txnHash}
                                </span>
                              </MiddleEllipsis>
                            ) : (
                              <span className="text-green-500 font-bold">
                                Copied!
                              </span>
                            )}
                            <FontAwesomeIcon
                              color="#0E7C65"
                              icon={faCopy}
                              className="ml-3 z-20"
                              size="lg"
                            />
                          </div>
                        ) : (
                          "-"
                        )}
                      </CopyToClipboard>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div
                    className={`flex mt-2 items-center expand absolute`}
                    style={{ marginLeft: "15px", height: "340px" }}
                  >
                    <p>
                      Client Email:{" "}
                      <span className="font-bold">{item.clientEmail}</span>
                    </p>
                    {item.clientEmail === "none" && (
                      <FontAwesomeIcon
                        color="#0E7C65"
                        icon={faEdit}
                        className="ml-3 z-20"
                        size="2x"
                        onClick={() => openModal(true)}
                      />
                    )}
                  </div>
                  <div
                    className="flex mt-2 items-center expand absolute"
                    style={{ marginLeft: "15px", height: "405px" }}
                  >
                    <p>
                      Date created:{" "}
                      <span className="font-bold">{item.dateTimeCreated}</span>
                    </p>
                  </div>
                </div>
              )}
            </td>
          </tr>
        </>
      )
      }
    </tbody >
  );
};

export default TableDataTransactions;
