import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  apiRequestLimits,
  getTransactions,
  getTransactionsChartData,
  getVolumes,
} from "../store/actions";
import useWindowSize from "../utils/useWindowSize";
import DataLoader from "../components/globalView/DataLoader";
import TransactionsChart from "../components/charts/TransactionsChart";
import CoinProfitPieChart from "../components/charts/IncomePie";
import TableDataVolumes from "../components/tables/TableDataVolumes";
import { Helmet } from "react-helmet";
import TableDataHomeTransactions from "../components/tables/TableDataHomeTransactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faDollarSign } from "@fortawesome/free-solid-svg-icons";


const Dashboard = () => {
  const dispatch = useDispatch();
  const sized = useWindowSize();

  const [tableOpen, setTableOpen] = useState("");
  const [leftDiv, setLeftDiv] = useState(true);  // set to true so Recent Transactions is open on mobile initially
  const [middleDiv, setMiddleDiv] = useState(true);  // set to true so Total Income is open on mobile initially
  const [monthsBefore, setMonthsBefore] = useState(0);
  const [apiStatus, setApiStatus] = useState("SUCCESS"); // apiStatus state

  const user = useSelector((state) => state.userReducer.user);
  const isLoading = useSelector((state) => state.userReducer.loading);
  const apiRequests = useSelector((state) => state.userReducer.apiRequests);
  const volumes = useSelector((state) => state.userReducer.volumes);
  const transactions = useSelector((state) => state.userReducer.transactions);
  const transactionsArray =
    transactions &&
    Object.keys(transactions).length > 0 &&
    transactions.transactions;
  const transactionsChartData = useSelector(
    (state) => state.userReducer.transactionsChartData
  );

  useEffect(() => {
    // Initial dashboard requests on mount
    dispatch(getTransactions());
    dispatch(getVolumes());
    dispatch(apiRequestLimits());
  }, [dispatch]);

  useEffect(() => {
    // Only update chart data when monthsBefore changes
    setApiStatus("LOADING");
    dispatch(getTransactionsChartData(monthsBefore))
      .then((response) => {
        if (response && response.status === 200) {
          setApiStatus("SUCCESS");
        } else {
          setApiStatus("ERROR");
        }
      })
      .catch(() => {
        setApiStatus("ERROR");
      });
  }, [dispatch, monthsBefore]);

  const currentDiv = (id) => {
    setTableOpen(id);
  };

  const handlePrevMonth = () => {
    setApiStatus("LOADING");
    setMonthsBefore(prev => prev + 1);
  };

  const handleNextMonth = () => {
    setApiStatus("LOADING");
    setMonthsBefore(prev => prev - 1);
  };

  const getCurrentMonth = () => {
    const today = new Date();
    today.setMonth(today.getMonth() - monthsBefore);
    const year = today.getFullYear();
    const month = today.toLocaleString("default", { month: "long" });
    return `${month} ${year}`;
  };

  // Only disable buttons during loading; error mode does not disable them.
  const disableButtons = apiStatus === "LOADING";

  const calcDiff = (current, previous) => {
    if (previous === null || previous === undefined || previous === 0) return null;
    return ((current - previous) / Math.abs(previous)) * 100;
  };

  const formattedDiff = (current, previous) => {
    const diff = calcDiff(current, previous);
    if (diff === null) return null;
    const rounded = diff.toFixed(1);
    return (
      <span style={{ color: diff >= 0 ? "green" : "red", marginLeft: "5px", fontSize: "12px" }}>
        {diff >= 0 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />} {rounded}%
      </span>
    );
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>Dashboard | Instaswap Partners</title>{" "}
      </Helmet>
      {isLoading && user && Object.keys(user).length > 0 ? (
        <DataLoader />
      ) : (
        <>
          <div className="px-4 md:px-0 main-area">
            <div className="flex items-center md:flex-row flex-col justify-between">
              {" "}
              <h1 className="text-4xl text-white mb-4">Overview</h1>{" "}
            </div>
            {transactionsChartData && (
              <div className="pb-4 rounded-md" style={{ width: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#292929", padding: "10px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
                    <div style={{ color: "white", fontWeight: "bold" }}>Total Orders</div>
                    <p className="text-white mt-2 w-full text-center">
                      {transactionsChartData?.currentMonth?.SumAnnualOrders ?? 0}
                    </p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#292929", padding: "10px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
                    <div style={{ color: "white", fontWeight: "bold" }}>Monthly Orders</div>
                    <p className="text-white mt-2 w-full text-center">
                      {transactionsChartData?.currentMonth?.SumCompletedMonthlyOrders ?? 0}
                      {transactionsChartData?.previousMonth?.SumCompletedMonthlyOrders != null &&
                        formattedDiff(
                          transactionsChartData?.currentMonth?.SumCompletedMonthlyOrders,
                          transactionsChartData?.previousMonth?.SumCompletedMonthlyOrders
                        )}
                    </p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#292929", padding: "10px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
                    <div style={{ color: "white", fontWeight: "bold" }}>Max Orders in a Day</div>
                    <p className="text-white mt-2 w-full text-center">
                      {transactionsChartData?.currentMonth?.maxCompletedOrdersNumOnADay ?? 0}
                      {transactionsChartData?.previousMonth?.maxCompletedOrdersNumOnADay != null &&
                        formattedDiff(
                          transactionsChartData?.currentMonth?.maxCompletedOrdersNumOnADay,
                          transactionsChartData?.previousMonth?.maxCompletedOrdersNumOnADay
                        )}
                    </p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#292929", padding: "10px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
                    <div style={{ color: "white", fontWeight: "bold" }}>Monthly Volume</div>
                    <p className="text-white mt-2 w-full text-center">
                      {transactionsChartData?.currentMonth?.monthlyVolume ?? 0}
                      {transactionsChartData?.previousMonth?.monthlyVolume != null &&
                        formattedDiff(
                          transactionsChartData?.currentMonth?.monthlyVolume,
                          transactionsChartData?.previousMonth?.monthlyVolume
                        )}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div
              className="text-md xl:hidden text-white rounded-md lg:flex flex-row items-center gap-4 p-2 w-full mr-2 p-4"
              style={{ background: "#292929" }}
            >
              <p className="py-1">
                Usage: Quotes Routed:{" "}
                <span style={{ color: "#24ae7a" }}>
                  {apiRequests.usedRequests}
                </span>
              </p>

            </div>

            <div className="dashboard-container">


              {/* Left Container DESKTOP*/}
              <div className="text-white font-medium xl:flex flex-col xl:overflow-y-auto mr-4 xl:mb-0 hidden">
                <div className="flex justify-between items-center" style={{ backgroundColor: "#292929", padding: "10px 10px 0px 10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                  {apiStatus !== "ERROR" && (
                    <button
                      onClick={handlePrevMonth}
                      className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      disabled={disableButtons}
                    >
                      Previous Month
                    </button>
                  )}
                  <h2 className="text-white text-xl">
                    {getCurrentMonth()}
                  </h2>
                  {monthsBefore > 0 && (
                    <button
                      onClick={handleNextMonth}
                      className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      disabled={disableButtons}
                    >
                      Next Month
                    </button>
                  )}
                </div>
                <div className="rounded-md p-4" style={{ backgroundColor: "#292929" }}>
                  {apiStatus === "LOADING" ? (
                    <div style={{ minHeight: "58.5vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <DataLoader />
                    </div>
                  ) : (
                    volumes &&
                    Object.keys(volumes).length > 0 &&
                    sized.width > 768 && <TransactionsChart />
                  )}
                </div>
                <div
                  className="mt-2 rounded-md p-0 md:p-4"
                  style={{
                    backgroundColor: "#292929",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  <h1 className="font-semibold mt-6 mx-4 xl:mx-0">
                    {" "}
                    Recent Transactions
                  </h1>
                  {/* <hr className="mt-2" /> */}
                  <table className="min-w-full">
                    {transactionsArray &&
                      transactionsArray.length > 0 &&
                      transactionsArray
                        .slice(0, 5)
                        .map((item) => (
                          <TableDataHomeTransactions
                            key={item.id}
                            item={item}
                            tableOpen={tableOpen}
                            currentDiv={currentDiv}
                            className="cursor-pointer"
                          />
                        ))}
                  </table>
                </div>
              </div>

              {/* Right Container MOBILE */}
              <div className="block xl:hidden my-4 p-4" style={{ backgroundColor: "#292929" }}>
                <div
                  style={{ backgroundColor: "#292929" }}
                  className=" text-white font-medium  overflow-y-auto my-2 rounded-md"
                >
                  <div
                    className="flex items-center justify-between"
                    onClick={() => setMiddleDiv(!middleDiv)}
                  >
                    <span className="font-semibold">Total Income:</span> &nbsp;
                    <div
                      style={{ backgroundColor: "rgba(12,213,111,0.26)", borderTopRightRadius: '10px', borderBottomRightRadius: '10px', marginLeft: "15%" }}
                      className="flex items-center rounded-sm p-1.2"
                    >
                      ≅&nbsp;
                      {volumes &&
                        Object.keys(volumes).length > 0 &&
                        volumes.sumBtcVolume.toFixed(1)}{" "}
                      &nbsp;
                      <FontAwesomeIcon
                        color="white"
                        icon={faDollarSign}
                        className="customUsdIcon"
                      />
                    </div>
                    <FontAwesomeIcon
                      color="white"
                      icon={!middleDiv ? faChevronDown : faChevronUp}
                      className="ml-2"
                    />
                  </div>
                </div>

                {middleDiv &&
                  volumes.calculatedVolumesPerCoin &&
                  volumes.calculatedVolumesPerCoin.length > 0 &&
                  volumes.calculatedVolumesPerCoin.map((item) => (
                    <div style={{ color: "white" }}>
                      <TableDataVolumes key={item.id} item={item} />{" "}
                    </div>
                  ))}
              </div>

              {/* Left Container MOBILE*/}

              <div className="flex flex-col xl:hidden mt-4">
                <div
                  style={{ backgroundColor: "#292929" }}
                  className="p-4 rounded-md text-white font-medium  overflow-y-auto"
                  onClick={() => setLeftDiv(!leftDiv)}
                >
                  <div className="flex items-center justify-between lg:mt-4 mt-0">
                    {" "}
                    <h1 className="font-semibold"> Recent Transactions</h1>{" "}
                    <FontAwesomeIcon
                      color="white"
                      icon={!leftDiv ? faChevronDown : faChevronUp}
                      className="ml-2"
                    />
                  </div>
                </div>

                {leftDiv && (
                  <table className="min-w-full mb-3">
                    {transactionsArray &&
                      transactionsArray.length > 0 &&
                      transactionsArray.slice(0, 2).map((item) => (
                        <TableDataHomeTransactions
                          key={item.id}
                          item={item}
                          tableOpen={tableOpen}
                          currentDiv={currentDiv}
                        />
                      ))}
                  </table>
                )}
              </div>
              {/* Right Container DESKTOP */}
              <div
                className="p-4 rounded-md text-white font-medium xl:overflow-y-auto xl:mb-0 mb-2 xl:block hidden"
                style={{ backgroundColor: "#292929", maxHeight: "100%" }}
              >
                <div className="flex items-center justify-between xl:mx-0">
                  <span className="font-semibold">Total Income:</span> &nbsp;
                  <div
                    style={{ backgroundColor: "rgba(12,213,111,0.26)", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
                    className="flex items-center rounded-sm p-1.2"
                  >
                    ≅&nbsp;
                    {volumes &&
                      Object.keys(volumes).length > 0 &&
                      volumes.sumBtcVolume.toFixed(1)}{" "}
                    &nbsp;
                    <FontAwesomeIcon
                      color="white"
                      icon={faDollarSign}
                      className="customUsdIcon"
                    />
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <CoinProfitPieChart />
                </div>
                <hr className="mt-2" />
                {volumes.calculatedVolumesPerCoin &&
                  volumes.calculatedVolumesPerCoin.length > 0 &&
                  volumes.calculatedVolumesPerCoin.map((item) => (
                    <TableDataVolumes key={item.id} item={item} />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
