import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "../scss/index.scss";
import FormikLogin from "../formik/FormikLogin";
import mobileLogo from "../images/mobileLogo.png";
import mobileTaurus from "../images/loginTaurusDesktop2.webp";
import text from "../images/ISPartnersLogo.png";
import logo from "../images/loginTaurusDesktop2.webp";
import mobileText from "../images/instaswapFullLogoSmall-dark.png";
import useWindowSize from "../utils/useWindowSize";
import { Helmet } from "react-helmet";
import { autoLoginUser } from "../store/actions/user.actions";


const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const authenticated = useSelector((state) => state.userReducer.authenticated);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [user, authenticated, navigate]);

  // New code to check for ssid in URL query parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ssid = searchParams.get("ssid");
    if (ssid && ssid.trim()) {
      dispatch(autoLoginUser(ssid));
    }
  }, [location, dispatch]);

  const sized = useWindowSize();

  return (
    <>
      <Helmet>
        {" "}
        <title>Log In | Instaswap Partners</title>{" "}
      </Helmet>
      <div className="form-container">
        <div
          className="form-content-left"
          style={
            sized.width < 768 ? { display: `none` } : null
          }
        >
          <div className="w-full justify-center flex">
            <img src={text} className="form-content-left-text" alt="logo" />

            <img
              style={sized.width < 769 ? { display: "none" } : null}
              src={sized.width < 769 ? mobileLogo : logo}
              alt="logo"
              className="form-content-left-logo"
            />
          </div>
        </div>
        <div className={sized.width > 769 ? "form-content-right mainBGWhite" : "form-content-right mainBGWhiteMob"}>
          <div style={{ display: "flex", height: '100vh', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <div style={sized.width < 769 ? { marginTop: "auto", marginBottom: 'auto', backgroundColor: 'rgb(255 255 255 / 50%)', borderRadius: '8px' } : { width: '100%' }}>
              <div className="form ">
                <div
                  className="form-mobile-logo"
                  style={sized.width > 769 ? { display: "none" } : null}
                >
                  <img src={mobileText} alt="logo" />
                </div>
                <h2 className="text-center text-3xl font-extrabold text-gray-900">
                  Login To Your Account
                </h2>
                <FormikLogin />
              </div>
            </div>
          </div>
          <img
            src={mobileTaurus}
            alt="logo"
            style={sized.width > 769 ? { display: "none" } : { zIndex: "-1" }}
            className="form-content-left-logo"
          />
        </div>
      </div>
    </>
  );
};

export default Login;
