import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
//TODOGZ reenable
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import store from "./store";
import Provider from "react-redux/es/components/Provider";
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/* ToastContainer is a required component for react-toastify */}
      <ToastContainer
        position="top-center" // Example position; change as needed
        autoClose={10000} // Example timeout duration; change as needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // Example theme; change as needed
      />
    </BrowserRouter>
  </Provider>,
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/sw.js")
      .then((registration) => {
        console.log("Service Worker registered with scope:", registration.scope);
      })
      .catch((err) => {
        console.error("Service Worker registration failed:", err);
      });
  });
}

let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the default mini-infobar from appearing
  e.preventDefault();
  deferredPrompt = e;

  if (!window.localStorage.getItem('pwaPromptShown')) {
    // Create a custom snackbar for the install prompt
    const snackbar = document.createElement('div');
    snackbar.id = "pwaSnackbar";
    snackbar.style.position = "fixed";
    snackbar.style.left = "50%";
    snackbar.style.bottom = "20px";
    snackbar.style.transform = "translateX(-50%)";
    snackbar.style.backgroundColor = "#323232";
    snackbar.style.color = "#fff";
    snackbar.style.padding = "16px";
    snackbar.style.borderRadius = "4px";
    snackbar.style.boxShadow = "0 2px 4px rgba(0,0,0,0.3)";
    snackbar.style.display = "flex";
    snackbar.style.alignItems = "center";
    snackbar.style.zIndex = "1000";

    // Message text
    const message = document.createElement('span');
    message.innerText = "Install this app for a better experience.";
    snackbar.appendChild(message);

    // Spacer between message and buttons
    const spacer = document.createElement('div');
    spacer.style.flex = "1";
    snackbar.appendChild(spacer);

    // INSTALL action button with updated styling
    const actionButton = document.createElement('button');
    actionButton.innerText = "INSTALL";
    actionButton.style.background = "rgb(76, 175, 80)";
    actionButton.style.border = "none";
    actionButton.style.color = "white";
    actionButton.style.fontWeight = "bold";
    actionButton.style.cursor = "pointer";
    actionButton.style.padding = "10px 5px 10px 5px";
    actionButton.style.borderRadius = "8px";
    actionButton.style.marginLeft = "20px";
    snackbar.appendChild(actionButton);

    // DISMISS button with similar styling adjustments
    const dismissButton = document.createElement('button');
    dismissButton.innerText = "DISMISS";
    dismissButton.style.background = "transparent";
    dismissButton.style.border = "none";
    dismissButton.style.color = "white";
    dismissButton.style.fontWeight = "bold";
    dismissButton.style.cursor = "pointer";
    dismissButton.style.padding = "10px 5px 10px 5px";
    dismissButton.style.borderRadius = "8px";
    dismissButton.style.marginLeft = "10px";
    snackbar.appendChild(dismissButton);

    document.body.appendChild(snackbar);

    actionButton.addEventListener('click', () => {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log(`User response to the install prompt: ${choiceResult.outcome}`);
        window.localStorage.setItem('pwaPromptShown', 'true');
        if (snackbar.parentNode) {
          snackbar.parentNode.removeChild(snackbar);
        }
        deferredPrompt = null;
      });
    });

    dismissButton.addEventListener('click', () => {
      window.localStorage.setItem('pwaPromptShown', 'true');
      if (snackbar.parentNode) {
        snackbar.parentNode.removeChild(snackbar);
      }
      deferredPrompt = null;
    });
  }
});
