import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { logout, changePage } from "../../store/actions";
import { Link } from "react-router-dom";
import defaultUserIcon from "../../images/is-icon.png"; // Import default user icon

const DropDown = ({ isOpen, toggleIsOpen, userIcon }) => {
  const dispatch = useDispatch();
  const partnerEmail = useSelector(
    (state) => state.userReducer.user.response.email,
  );

  const handleLogout = () => {
    window.location.reload();
    localStorage.clear();

    setTimeout(() => {
      dispatch(logout());
    }, 800);
  };

  const handleChangePage = (value) => {
    dispatch(changePage(value));
  };

  return (
    <div className="relative block cursor-pointer">
      <div
        className="flex justify-between items-center px-2 rounded text-center"
        onClick={toggleIsOpen}
      >
        <img
          src={userIcon || defaultUserIcon} // Use default icon if userIcon is not provided
          className="image-navbar"
          style={{
            width: userIcon ? "30px" : "30px", 
            borderRadius: userIcon ? "20px" : "10px", 
            padding: userIcon ? "0" : "5px", // Add padding if default icon
            backgroundColor: userIcon ? "transparent" : "#2d2d2d", // Change background color if default icon
          }}
          alt="user-avatar"
        />
        <FontAwesomeIcon
          color="white"
          icon={!isOpen ? faChevronDown : faChevronUp}
          className="ml-2"
        />
      </div>

      {isOpen && (
        <div className="user-menu-wrap">
          <div className="menu-container">
            <ul className="user-menu">
              <div className="profile-highlight">
                <div className="details">
                  <div id="profile-name">{partnerEmail}</div>
                </div>
              </div>
              <div className="footer">
                <li className="user-menu__item"
                  onClick={() => {
                    handleChangePage("change-password");
                  }}>
                  <Link className="user-menu-link" to="/change-password">
                    Change Password
                  </Link>
                </li>
                <li className="user-menu__item"
                    onClick={() => {
                      handleChangePage("whitelisted-ips");
                    }}>
                  <Link className="user-menu-link" to="/whitelisted-ips">
                    Whitelisted API IP's
                  </Link>
                </li>
                <li className="user-menu__item"
                    onClick={() => {
                      handleChangePage("whitelisted-ips");
                    }}>
                  <Link className="user-menu-link" to="/whitelisted-ips">
                    Whitelisted Widget URL's
                  </Link>
                </li>
                <li className="user-menu__item"
                    onClick={() => {
                      handleChangePage("fee-management");
                    }}>
                  <Link className="user-menu-link" to="/fee-management">
                    Partner Fee
                  </Link>
                </li>
                <li className="user-menu__item"
                 onClick={() => {
                  handleChangePage("edit-wallet");
                }}>
                  <Link className="user-menu-link" to="/edit-wallet">
                    Funding Wallet
                  </Link>
                </li>
                <li className="user-menu__item">
                  <Link
                    className="user-menu-link"
                    to="/login"
                    style={{ color: "#F44336", fontWeight: "bold" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
