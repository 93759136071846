import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";

const IncomePie = () => {
    const [chartData, setChartData] = useState(null);

    // Fetch the volumes data from the Redux store
    const volumes = useSelector((state) => state.userReducer.volumes);

    useEffect(() => {
        if (
            volumes &&
            volumes.calculatedVolumesPerCoin &&
            volumes.calculatedVolumesPerCoin.length > 0
        ) {
            const labels = volumes.calculatedVolumesPerCoin.map((coin) => coin.name);
            const data = volumes.calculatedVolumesPerCoin.map((coin) => coin.SumUSDProfit);

            // Get the most used color for each coin from the Redux store
            const backgroundColors = volumes.calculatedVolumesPerCoin.map(
                (coin) => coin.color || "#CCCCCC" // Default to grey if no color is found
            );

            // Set the data for the pie chart
            setChartData({
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: backgroundColors, // Use the colors from the Redux store
                        hoverBackgroundColor: backgroundColors,
                    },
                ],
            });
        }
    }, [volumes]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: "#d5d5d5",
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const coin = volumes.calculatedVolumesPerCoin[context.dataIndex];
                        return `${coin.SumUSDProfit.toFixed(2)} USD (${coin.SumProfit.toFixed(
                            2
                        )} ${coin.shortname})`;
                    },
                },
                backgroundColor: "#1d1d1d",
                titleFont: {
                    size: 12,
                    weight: "bold",
                },
                bodyFont: {
                    size: 12,
                },
                borderColor: "#ffffff",
                borderWidth: 1,
                cornerRadius: 5,
            },

        },
    };

    return (
        <div className="w-4/4 m-auto">
            {chartData ? (
                <Chart type="pie" data={chartData} options={options} />
            ) : (
                <p style={{ color: "#d5d5d5", textAlign: "center" }}>Waiting for data...</p>
            )}
        </div>
    );
};

export default IncomePie;
