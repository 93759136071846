import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changePage } from "../store/actions";
import "react-tabs/style/react-tabs.css";

const Widget = () => {
    const affiliateId = localStorage.getItem("affiliateId");
    const type = "floating"; // or "static"
    const horizontalPosition = "right"; // or "right"
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const codeSnippets = {
        javascript: `
<div id="instaswap-widget-container"></div>
<script src="https://web.instaswap.com/widget.js"></script>
<script>
  InstaswapWidget.init({
    affiliateId: "${affiliateId}",
    type: "${type}",                    // options: "floating", "static"
    horizontalPosition: "${horizontalPosition}",        // options: "left", "right"
    verticalPosition: "top",           // "top", or "bottom"
    previewText: "Trade Now",         // Only first 10 letters will be shown
    sellAsset: "BTC.BTC",            // optional – default is "BTC.BTC"
    buyAsset: "ETH.ETH",            // optional – default is "ETH.ETH"
    sellAmount: '1'
  });
</script>
    `,
        react: `
import { InstaswapWidget } from 'instaswap-widget';
const App = () => (
  <>
    <div id="instaswap-widget-container"></div>
    <InstaswapWidget 
      affiliateId="${affiliateId}" 
      type="${type}" // options: "floating", "static"
      horizontalPosition="${horizontalPosition}" // options: "left", "right"
      verticalPosition="top" // "top", or "bottom"
      previewText="Trade Now" // Only first 10 letters will be shown
      sellAsset="BTC.BTC" // optional – default is "BTC.BTC"
      buyAsset="ETH.ETH" // optional – default is "ETH.ETH"
      sellAmount="1"
    />
  </>
);
export default App;
    `,
        nextjs: `
import dynamic from 'next/dynamic';
const InstaswapWidget = dynamic(() => import('instaswap-widget'), { ssr: false });
const Home = () => (
  <>
    <div id="instaswap-widget-container"></div>
    <InstaswapWidget 
      affiliateId="${affiliateId}" 
      type="${type}" // options: "floating", "static"
      horizontalPosition="${horizontalPosition}" // options: "left", "right"
      verticalPosition="top" // "top", or "bottom"
      previewText="Trade Now" // Only first 10 letters will be shown
      sellAsset="BTC.BTC" // optional – default is "BTC.BTC"
      buyAsset="ETH.ETH" // optional – default is "ETH.ETH"
      sellAmount="1"
    />
  </>
);
export default Home;
    `,
        vue: `
<template>
  <div id="instaswap-widget-container"></div>
  <InstaswapWidget 
    :affiliateId="${affiliateId}" 
    :type="${type}" // options: "floating", "static"
    :horizontalPosition="${horizontalPosition}" // options: "left", "right"
    verticalPosition="top" // "top", or "bottom"
    previewText="Trade Now" // Only first 10 letters will be shown
    sellAsset="BTC.BTC" // optional – default is "BTC.BTC"
    buyAsset="ETH.ETH" // optional – default is "ETH.ETH"
    sellAmount="1"
  />
</template>
<script>
import InstaswapWidget from 'instaswap-widget';
export default {
  components: { InstaswapWidget }
};
</script>
    `,
        angular: `
import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: '<div id="instaswap-widget-container"></div><instaswap-widget [affiliateId]="${affiliateId}" [type]="${type}" [horizontalPosition]="${horizontalPosition}" verticalPosition="top" previewText="Trade Now" sellAsset="BTC.BTC" buyAsset="ETH.ETH" sellAmount="1"></instaswap-widget>'
})
export class AppComponent {}
    `
    };

    const runJavaScriptCode = () => {
        if (window.InstaswapWidget && document.getElementById("instaswap-widget-container").innerHTML.trim() !== "") {
            console.log("Widget already initialized");
            return;
        }

        const container = document.createElement("div");
        container.id = "instaswap-widget-container";

        document.body.appendChild(container);

        const script = document.createElement("script");
        script.src = "https://web.instaswap.com/widget.js?ver=2";
        script.onload = () => {
            if (window.InstaswapWidget) {
                window.InstaswapWidget.init({
                    affiliateId: affiliateId,
                    type: type,
                    horizontalPosition: horizontalPosition,
                    verticalPosition: "bottom",
                    previewText: "Trade Now",
                    sellAsset: "BTC.BTC",
                    buyAsset: "ETH.ETH",
                    sellAmount: '1'
                });
            } else {
                console.error("InstaswapWidget is not defined");
            }
        };
        document.body.appendChild(script);
    };

    const handleWhitelistRedirect = () => {
        dispatch(changePage("whitelisted-websites"));
        navigate("/whitelisted-websites");
    };

    return (
        <>
            <Helmet>
                <title>Widget | Instaswap Partners</title>
            </Helmet>
            <div className="main-area px-4 py-6">
                <h1 className="text-4xl text-white mb-4">Widget Integration</h1>
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-1/2 text-gray-100" style={{ color: "#b8c2cc", fontSize: "25px", paddingRight: "50px" }}>
                        <p className="mb-4">
                            Enhance your website or application by embedding the Instaswap widget.
                        </p>
                        <p className="mb-4">
                            This allows your users to seamlessly swap cryptocurrencies right from your platform.
                        </p>
                        <p className="mb-4" style={{ color: "whitesmoke" }}>
                            Here's how to get started:
                        </p>
                        <p style={{ color: "whitesmoke" }}>
                            Select Your Language:
                        </p>
                        <p className="mb-4" style={{ fontSize: "1.1rem" }}>
                            Choose from a variety of programming languages to match your development environment.
                        </p>
                        <p style={{ color: "whitesmoke" }}>
                            Follow Our Guide:
                        </p>
                        <p className="mb-4" style={{ fontSize: "1.1rem" }}>
                            Detailed instructions are provided to help you integrate the widget effortlessly.
                        </p>
                        <p style={{ color: "whitesmoke" }}>
                            Profit from Every Swap:
                        </p>
                        <p className="mb-4" style={{ fontSize: "1.1rem" }}>
                            Like our referral program, you earn Bitcoin for each transaction your users make via the widget.
                        </p>
                        <p className="mb-4" style={{ color: "whitesmoke" }}>
                            By adding the Instaswap widget, you provide an additional layer of functionality to your users, making your platform a one-stop shop for cryptocurrency transactions.
                        </p>
                        <p className="mb-4" style={{ color: "whitesmoke" }}>
                            Before you proceed, please make sure to whitelist the URLs of the websites where you want to install the widget.
                        </p>
                        <button
                            onClick={handleWhitelistRedirect}
                            style={{ marginTop: "10px", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}
                        >
                            Whitelist Your Websites
                        </button>
                    </div>
                    <div className="lg:w-1/2 p-4 rounded-md" style={{ backgroundColor: "#1d1d1d", color: "white" }}>
                        <div id="instaswap-widget-container"></div>
                        <Tabs>
                            <TabList>
                                <Tab>JavaScript</Tab>
                                <Tab>React</Tab>
                                <Tab>Next.js</Tab>
                                <Tab>Vue</Tab>
                                <Tab>Angular</Tab>
                            </TabList>
                            <TabPanel>
                                <pre className="text-white p-4 rounded-md overflow-auto" style={{ backgroundColor: "#2d2d2d" }}>
                                    <code>{codeSnippets.javascript}</code>
                                </pre>
                                <button onClick={runJavaScriptCode} style={{ marginTop: "10px", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}>
                                    Run JavaScript Code
                                </button>
                            </TabPanel>
                            <TabPanel>
                                <pre className="text-white p-4 rounded-md" style={{ backgroundColor: "#2d2d2d" }}>
                                    <code>{codeSnippets.react}</code>
                                </pre>
                            </TabPanel>
                            <TabPanel>
                                <pre className=" text-white p-4 rounded-md" style={{ backgroundColor: "#2d2d2d" }}>
                                    <code>{codeSnippets.nextjs}</code>
                                </pre>
                                <button onClick={runJavaScriptCode} style={{ marginTop: "10px", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}>
                                    Run Next.js Code
                                </button>
                            </TabPanel>
                            <TabPanel>
                                <pre className=" text-white p-4 rounded-md" style={{ backgroundColor: "#2d2d2d" }}>
                                    <code>{codeSnippets.vue}</code>
                                </pre>
                                <button onClick={runJavaScriptCode} style={{ marginTop: "10px", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}>
                                    Run Vue Code
                                </button>
                            </TabPanel>
                            <TabPanel>
                                <pre className=" text-white p-4 rounded-md" style={{ backgroundColor: "#2d2d2d", overflow: 'auto' }}>
                                    <code>{codeSnippets.angular}</code>
                                </pre>
                                <button onClick={runJavaScriptCode} style={{ marginTop: "10px", padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px" }}>
                                    Run Angular Code
                                </button>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Widget;
