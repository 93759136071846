import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner, faLinkSlash } from "@fortawesome/free-solid-svg-icons"; // Import the icon
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DataLoader from "../components/globalView/DataLoader";
import {
    getWhitelistedWebsites,
    addWhitelistedWebsite,
    deleteWhitelistedWebsite,
    handleLoadState
} from "../store/actions";

const isValidURL = (url) => {
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlRegex.test(url);
};

const WhitelistedWebsites = () => {
    const dispatch = useDispatch();
    const [newUrl, setNewUrl] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showAddUrlDiv, setShowAddUrlDiv] = useState(false);
    const [isAddingUrl, setIsAddingUrl] = useState(false);
    const [localUrls, setLocalUrls] = useState([]);

    const isLoading = useSelector((state) => state.userReducer.loading);
    const whitelistedWebsites = useSelector((state) => state.userReducer.whitelistedWebsites || []);

    useEffect(() => {
        dispatch(handleLoadState(true));
        dispatch(getWhitelistedWebsites()).finally(() => dispatch(handleLoadState(false)));
    }, [dispatch]);

    useEffect(() => {
        setLocalUrls(whitelistedWebsites);
    }, [whitelistedWebsites]);

    const addUrl = () => {
        if (isValidURL(newUrl)) {
            if (localUrls.includes(newUrl)) {
                setError('This URL is already in the list.');
            } else {
                setIsAddingUrl(true);
                dispatch(addWhitelistedWebsite(newUrl))
                    .then(() => {
                        setNewUrl('');
                        setSuccessMessage('URL added successfully');
                        setTimeout(() => setSuccessMessage(''), 2000);
                    })
                    .catch((err) => {
                        setError('Failed to add URL. Please try again.');
                    })
                    .finally(() => {
                        setIsAddingUrl(false);
                        setShowAddUrlDiv(false);
                    });
            }
        } else {
            setError('Please enter a valid URL.');
        }
    };

    const deleteUrl = (urlToDelete) => {
        // Optimistic update
        setLocalUrls(prevUrls => prevUrls.filter(url => url !== urlToDelete));
        dispatch(deleteWhitelistedWebsite(urlToDelete))
            .then(() => {
                setSuccessMessage('URL deleted successfully');
                setTimeout(() => setSuccessMessage(''), 2000);
            })
            .catch((err) => {
                // Revert optimistic update
                setLocalUrls(prevUrls => [...prevUrls, urlToDelete]);
                setError('Failed to delete URL. Please try again.');
                setTimeout(() => setError(''), 2000);
            });
    };

    if (isLoading) {
        return <DataLoader />;
    }

    return (
        <>
            <Helmet>
                <title>Website Whitelist Management | Instaswap Partners</title>
            </Helmet>
            <div className="px-0 main-area extraMn">
                <div className="flex md:flex-row flex-col justify-between items-center px-2">
                    <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                        <h1 className="text-4xl text-white w-full text-left">Whitelisted Websites</h1>
                        <h2 className="text-1xl text-white w-full text-left">Website whitelisting for widget integration</h2>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-start h-2/4 mt-20">
                    {!showAddUrlDiv && (
                        <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
                            <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                                {localUrls.length > 0 ? (
                                    <div>
                                        <ul className="grid grid-cols-2 gap-4 mt-5 mb-5">
                                            {localUrls.map((url) => (
                                                <li key={url} className="flex flex-col items-center text-white p-2 rounded-md">
                                                    <div className="w-48 h-48 mb-2" style={{ borderRadius: "8px", overflow: "hidden" }}>
                                                        <img src={`https://api.thumbnail.ws/api/ab5bb6ba655b9c5fa93eaa05aba576102bf9f98b2b3a/thumbnail/get?url=${url}&width=200`} alt={url} className="w-full h-full object-cover" />
                                                    </div>
                                                    <span className="text-center">{url}</span>
                                                    <button
                                                        onClick={() => deleteUrl(url)}
                                                        className="text-red-500 hover:text-red-700 focus:outline-none mt-2"
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center" style={{ height: '200px' }}>
                                        <FontAwesomeIcon icon={faLinkSlash} style={{ fontSize: 50, color: 'gray' }} />
                                        <p className="text-gray-200 mt-2">No URLs whitelisted yet.</p>
                                    </div>
                                )}
                            </div>
                            <button
                                className="w-full p-2 text-gray-200 rounded-md mt-6"
                                style={{ background: "#23ac79" }}
                                onClick={() => {
                                    setShowAddUrlDiv(true);
                                    setError('');
                                }}
                            >
                                Add New URL
                            </button>
                        </div>
                    )}

                    {showAddUrlDiv && (
                        <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
                            <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                                <p className="text-gray-200 mb-6 text-center">
                                    Please enter a valid URL to whitelist. Only requests from whitelisted URLs will be accepted.
                                </p>
                                <div className="flex items-center justify-center gap-4 w-full">
                                    <p className="text-gray-200 flex items-center">Website URL:</p>
                                    <input
                                        className="form-input"
                                        type="text"
                                        value={newUrl}
                                        onChange={(e) => setNewUrl(e.target.value)}
                                        placeholder="Enter website URL"
                                    />
                                </div>
                                <div className="flex w-full gap-4">
                                    <button
                                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                                        style={{ background: "#23ac79" }}
                                        onClick={addUrl}
                                        disabled={isAddingUrl}
                                    >
                                        {isAddingUrl ? (
                                            <FontAwesomeIcon color="white" icon={faSpinner} spin />
                                        ) : (
                                            <span>Add URL</span>
                                        )}
                                    </button>
                                    <button
                                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                                        style={{ background: "#F0544E" }}
                                        onClick={() => {
                                            setShowAddUrlDiv(false);
                                            setError('');
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {(error || successMessage) && (
                        <div className="xl:w-4/12 w-10/12 text-center p-2 mt-2 rounded">
                            <div className={`${error ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'} p-2 rounded`}>
                                {error || successMessage}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default WhitelistedWebsites;
