import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const Referral = () => {
    const [copied, setCopied] = useState(false);
    const affiliateUrl = "https://app.instaswap.com/?affiliate=" + localStorage.getItem("affiliateId");

    return (
        <>
            <Helmet>
                <title>Referral | Instaswap Partners</title>
            </Helmet>
            <div className="main-area px-4 py-6">
                <h1 className="text-4xl text-white mb-4">Referral Program</h1>
                <div className="text-gray-100 text-xl" style={{ color: "#b8c2cc" }}>
                    <p className="mb-4">
                        Unlock the potential to earn Bitcoin by sharing your unique referral link. Every time someone completes a swap using your link, you earn BTC. The more swaps, the more you earn!
                    </p>

                    <h2 className="text-2xl text-white mt-6 mb-2">How It Works</h2>
                    <ul className="list-disc ml-4 mb-4">
                        <li>Copy Your Link: Grab your unique referral link from your account.</li>
                        <li>Spread the Word: Share it with friends, colleagues, or across your social networks.</li>
                        <li>Earn BTC: Receive a percentage of Bitcoin for each successful swap made through your link.</li>
                    </ul>
                    <h2 className="text-2xl text-white mt-6 mb-2">Why Refer?</h2>
                    <p className="mb-4">
                        Community Growth: By inviting others, you expand our network and increase the platform's liquidity.
                    </p>
                    <p className="mb-4">
                        Mutual Benefits: It's a rewarding cycle - your referrals gain access to our services, and you earn Bitcoin for each transaction. Everyone wins!
                    </p>
                    <p className="mt-4">
                        <div style={{ width: "100%", color: 'white' }}>Here is your affiliate link (valid for 90 days):</div>
                    </p>

                    <div style={{ width: "100%", color: 'white', display: "flex", maxWidth: "60%" }}>
                        <button className="bg-green-500 text-white mt-6 px-4 py-2 rounded-md hover:bg-green-700 mr-4" style={{ width: "80%" }}>
                            <p className=" flex items-center justify-between flex-col">
                                <div className="truncate" style={{ width: "100%", fontSize: "13px" }}>{affiliateUrl}</div>
                            </p>
                        </button>
                        <button className="bg-green-500 text-white mt-6 px-4 py-2 rounded-md hover:bg-green-700" style={{ width: "20%" }}>
                            <CopyToClipboard text={affiliateUrl} onCopy={() => setCopied(true)}>
                                <FontAwesomeIcon className="cursor-pointer" icon={faCopy} />
                            </CopyToClipboard>
                            {copied && (
                                <div className="text-sm text-white-500 mb-2">
                                    Copied!
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Referral;
