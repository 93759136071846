import axios from "axios";

export const LOADING_STATE = "LOADING_STATE";
export const CUSTOM_LOADER = "CUSTOM_LOADER";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTHENTICATED_USER = "AUTHENTICATED_USER";
export const UPDATE_BPS = "[USER] UPDATE_BPS";

export const AVAILABLE_PERMISSIONS = "[USER] AVAILABLE_PERMISSIONS";
export const GET_VOLUMES = "[USER] GET_VOLUMES";
export const GET_TRANSACTIONS_CHART_DATA = "[USER] GET_TRANSACTIONS_CHART_DATA";
export const API_REQUEST_LIMITS = "[USER] API_REQUEST_LIMITS";
export const GET_USER_TRANSACTIONS = "[USER] GET_USER_TRANSACTIONS";
export const GET_USER_WITHDRAW_HISTORY = "[USER] GET_USER_WITHDRAW_HISTORY";
export const GET_ACTIVE_WALLET = "[USER] GET_ACTIVE_WALLET";
export const CHANGE_WALLET = "[USER] CHANGE_WALLET";
export const WITHDRAW_FEES = "[USER] WITHDRAW_FEES";
export const UPDATE_CLIENT_EMAIL = "[USER] UPDATE_CLIENT_EMAIL";

export const AVAILABLE_CHAINS = "AVAILABLE_CHAINS";
export const AVAILABLE_CURRENCIES = "AVAILABLE_CURRENCIES";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const CHANGE_ERROR_MESSAGE = "[FORM] CHANGE ERROR MESSAGE";

export const AUTH_LOADING = "AUTH_LOADING";

export const GET_WHITELISTED_IPS = "[USER] GET_WHITELISTED_IPS";
export const ADD_WHITELISTED_IP = "[USER] ADD_WHITELISTED_IP";
export const DELETE_WHITELISTED_IP = "[USER] DELETE_WHITELISTED_IP";
export const CHANGE_PASSWORD_SUCCESS = "[USER] CHANGE_PASSWORD_SUCCESS";

export const GET_WHITELISTED_WEBSITES = "[USER] GET_WHITELISTED_WEBSITES";
export const ADD_WHITELISTED_WEBSITE = "[USER] ADD_WHITELISTED_WEBSITE";
export const DELETE_WHITELISTED_WEBSITE = "[USER] DELETE_WHITELISTED_WEBSITE";

export const GET_QUOTES = "[USER] GET_QUOTES";

export const handleAuthLoading = (status) => ({
  type: AUTH_LOADING,
  payload: status,
});

export function loginUser(email, password) {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    const currentUrl = window.location.href;
    let payload = {
      login: "",
      username: email,
      urlUsed: currentUrl,
      password,
    };

    const request = axios.post(
      `https://ppapi.instaswap.com/formData`,
      payload
    );

    request
      .then((response) => {
        dispatch({
          type: LOGIN_USER,
          payload: response.data,
        });

        localStorage.setItem("hash", response.data.response.hash);
        localStorage.setItem("username", response.data.response.email);
        localStorage.setItem("expirationTime", new Date().getTime() + 14400000);
        localStorage.setItem("affiliateId", response.data.response.affiliateId);
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response && error.response.data) {
          if (error.response.data.response === "Incorect Hash") {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "Your session has expired",
            });
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "",
            });
            dispatch(logout());
          } else {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: error.response.data.response,
            });
          }
        }
      });
  };
}

export function autoLoginUser(ssid) {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    let payload = {
      autoLogin: "",
      ssid: ssid
    };
    const request = axios.post(
      `https://ppapi.instaswap.com/formData`,
      payload
    );

    request
      .then((response) => {
        dispatch({
          type: LOGIN_USER,
          payload: response.data,
        });

        localStorage.setItem("hash", response.data.response.hash);
        localStorage.setItem("username", response.data.response.email);
        localStorage.setItem("expirationTime", new Date().getTime() + 14400000);
        localStorage.setItem("affiliateId", response.data.response.affiliateId);
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response && error.response.data) {
          if (error.response.data.response === "Incorect Hash") {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "Your session has expired",
            });
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "",
            });
            dispatch(logout());
          } else {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: error.response.data.response,
            });
          }
        }
      });
  };
}

export const logout = () => ({
  type: LOGOUT_USER,
});

export function changePassword(
  currentPassword,
  newPassword,
  repeatNewPassword
) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");

    const currentUrl = window.location.href;


    let payload = {
      changePassword: "",
      urlUsed: currentUrl,
      oldPassword: currentPassword,
      newPassword,
      repeatNewPassword,
      hash: hashToken,
    };

    const request = axios.post(
      `https://ppapi.instaswap.com/formData`,
      payload
    );

    request
      .then((response) => {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
        dispatch(handleLoadState(false));
      })

      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response.data) {
          dispatch({
            type: CHANGE_ERROR_MESSAGE,
            payload: error.response.data.response,
          });
        }
      });
  };
}

export function resetPassword(email) {
  return (dispatch) => {

    const currentUrl = window.location.href;


    let payload = {
      forgotPassword: "",
      urlUsed: currentUrl,
      username: email,
    };

    const request = axios.post(
      `https://ppapi.instaswap.com/formData`,
      payload
    );

    request
      .then(() => { })
      .catch((error) => {
        if (error.response.data) {
          dispatch({
            type: CHANGE_ERROR_MESSAGE,
            payload: error.response.data.response,
          });
        }
      });
  };
}

export const handleCustomLoader = (status) => ({
  type: CUSTOM_LOADER,
  payload: status,
});

export const handleLoadState = (status) => ({
  type: LOADING_STATE,
  payload: status,
});

export function getAvailablePermissions() {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    let hashToken = localStorage.getItem("hash");

    const currentUrl = window.location.href;


    let payload = {
      availablePermissions: "",
      urlUsed: currentUrl,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: AVAILABLE_PERMISSIONS,
          payload: response.data.response,
        });
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}



export function getWhitelistedIps() {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    let hashToken = localStorage.getItem('hash');
    const currentUrl = window.location.href;

    let payload = {
      whitelistedIps: '',
      urlUsed: currentUrl,
      hash: hashToken,
    };

    return axios
      .post('https://ppapi.instaswap.com/formData', payload)
      .then((response) => {
        dispatch({
          type: GET_WHITELISTED_IPS,
          payload: response.data.response,
        });
      })
      .catch((error) => {
        console.error('Error fetching whitelisted IPs:', error);
      })
      .finally(() => {
        dispatch(handleLoadState(false));
      });
  };
}

export function addWhitelistedIp(ip) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      addWhitelistedIp: "",
      urlUsed: currentUrl,
      ip: ip,
      hash: hashToken,
    };

    return axios
      .post("https://ppapi.instaswap.com/formData", payload)
      .then(() => {
        dispatch({
          type: ADD_WHITELISTED_IP,
          payload: ip,
        });
      })
      .catch((error) => {
        console.error('Error adding whitelisted IP:', error);
        throw error;
      })
      .finally(() => {
      });
  };
}

export function deleteWhitelistedIp(ip) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      deleteWhitelistedIp: "",
      urlUsed: currentUrl,
      ip: ip,
      hash: hashToken,
    };

    return axios
      .post("https://ppapi.instaswap.com/formData", payload)
      .then(() => {
        dispatch({
          type: DELETE_WHITELISTED_IP,
          payload: ip,
        });
      })
      .catch((error) => {
        console.error('Error deleting whitelisted IP:', error);
        throw error;
      })
      .finally(() => {
      });
  };
}

export function getWhitelistedWebsites() {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    let hashToken = localStorage.getItem('hash');
    const currentUrl = window.location.href;

    let payload = {
      whitelistedWebsites: '',
      urlUsed: currentUrl,
      hash: hashToken,
    };

    return axios
      .post('https://ppapi.instaswap.com/formData', payload)
      .then((response) => {
        dispatch({
          type: GET_WHITELISTED_WEBSITES,
          payload: response.data.response,
        });
      })
      .catch((error) => {
        console.error('Error fetching whitelisted websites:', error);
      })
      .finally(() => {
        dispatch(handleLoadState(false));
      });
  };
}

export function addWhitelistedWebsite(url) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      addWhitelistedWebsite: "",
      urlUsed: currentUrl,
      url: url,
      hash: hashToken,
    };

    return axios
      .post("https://ppapi.instaswap.com/formData", payload)
      .then(() => {
        dispatch({
          type: ADD_WHITELISTED_WEBSITE,
          payload: url,
        });
      })
      .catch((error) => {
        console.error('Error adding whitelisted website:', error);
        throw error;
      })
      .finally(() => {
      });
  };
}

export function deleteWhitelistedWebsite(url) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      deleteWhitelistedWebsite: "",
      urlUsed: currentUrl,
      url: url,
      hash: hashToken,
    };

    return axios
      .post("https://ppapi.instaswap.com/formData", payload)
      .then(() => {
        dispatch({
          type: DELETE_WHITELISTED_WEBSITE,
          payload: url,
        });
      })
      .catch((error) => {
        console.error('Error deleting whitelisted website:', error);
        throw error;
      })
      .finally(() => {
      });
  };
}

export function getAvailableChains() {
  return (dispatch) => {
    dispatch(handleLoadState(true));

    const request = axios.get(
      "https://api.instaswap.com/v1/full/universal/chainsDetails",
    );
    request
      .then((response) => {
        dispatch({
          type: AVAILABLE_CHAINS,
          payload: response.data.response,
        });
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function getAvailableCurrencies() {
  return (dispatch) => {
    dispatch(handleLoadState(true));

    const request = axios.get(
      "https://api.instaswap.com/v1/full/universal/currencies-cached",
    );
    request
      .then((response) => {
        dispatch({
          type: AVAILABLE_CURRENCIES,
          payload: response.data.response.response,
        });
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function getVolumes() {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      balances: "",
      urlUsed: currentUrl,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: GET_VOLUMES,
          payload: response.data.response,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function apiRequestLimits() {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      requestsGraph: "",
      urlUsed: currentUrl,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: API_REQUEST_LIMITS,
          payload: response.data.response,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function getTransactions(item, per_page) {
  return (dispatch) => {
    if (item) {
      dispatch(handleCustomLoader(true));
    } else {
      dispatch(handleLoadState(true));
    }

    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      transactions: "",
      urlUsed: currentUrl,
      page: item ? item : 1,
      itemsPerPage: per_page ? per_page : 5,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: GET_USER_TRANSACTIONS,
          payload: response.data.response,
        });
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function getTransactionsChartData(monthsBefore = 0) {
  return (dispatch) => {
    return new Promise((resolve, reject) => { // Return a promise
      let hashToken = localStorage.getItem("hash");
      const currentUrl = window.location.href;

      let payload = {
        transactionsGraph: "",
        urlUsed: currentUrl,
        hash: hashToken,
        monthsBefore: monthsBefore, // added monthsBefore
      };

      const request = axios.post(
        "https://ppapi.instaswap.com/formData",
        payload
      );
      request
        .then((response) => {
          dispatch({
            type: GET_TRANSACTIONS_CHART_DATA,
            payload: response.data.response,
          });
          resolve({ status: 200 }); // Resolve with a success status
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
          }
          reject(error); // Reject the promise on error
        });
    });
  };
}

export function getWithdrawHistory(item) {
  return (dispatch) => {
    if (item) {
      dispatch(handleCustomLoader(true));
    } else {
      dispatch(handleLoadState(true));
    }

    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      withdrawHistory: "",
      urlUsed: currentUrl,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: GET_USER_WITHDRAW_HISTORY,
          payload: response.data.response,
        });
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function getActiveWallet(item) {
  return (dispatch) => {
    if (item) {
      dispatch(handleCustomLoader(true));
    } else {
      dispatch(handleLoadState(true));
    }

    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      gatherWithdrawWallet: "",
      urlUsed: currentUrl,
      hash: hashToken,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: GET_ACTIVE_WALLET,
          payload: response.data.response,
        });
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));
      })
      .catch((error) => {
        dispatch(handleCustomLoader(false));
        dispatch(handleLoadState(false));

        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function changeWallet(wallet) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      saveWithdrawWallet: "",
      hash: hashToken,
      urlUsed: currentUrl,
      wallet,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: CHANGE_WALLET,
          payload: response.data.response,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}

export function withdrawFees(amount) {
  return async (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      withdrawRequest: "",
      urlUsed: currentUrl,
      hash: hashToken,
      amount: amount,
    };

    try {
      const response = await axios.post(
        "https://ppapi.instaswap.com/formData",
        payload
      );
      dispatch({
        type: WITHDRAW_FEES,
        payload: response.data.response,
      });
      setTimeout(() => {
        dispatch({
          type: WITHDRAW_FEES,
          payload: "",
        });
      }, 10000);
      return response.data.response; // Return the response
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      throw error;
    }
  };
}

export function changePage(payload) {
  return (dispatch) => {
    const lastPage = localStorage.getItem("lastPage");
    if (lastPage) {
      localStorage.setItem("lastPage", payload);
    } else {
      localStorage.setItem("lastPage", payload);
    }

    dispatch({
      type: CHANGE_PAGE,
      payload: payload,
    });
  };
}

export function authenticateUser(hash, username) {
  return (dispatch) => {
    const currentUrl = window.location.href;

    let payload = {
      username: username,
      hash: hash,
      urlUsed: currentUrl,
      isLogin: "",
    };
    dispatch(handleAuthLoading(true));
    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: AUTHENTICATED_USER,
          payload: response.data,
        });
        localStorage.setItem("hash", response.data.response.hash);
        localStorage.setItem("username", response.data.response.email);
        localStorage.setItem("affiliateId", response.data.response.affiliateId);
        dispatch(handleAuthLoading(false));
      })
      .catch((error) => {
        dispatch(handleLoadState(false));
        if (error.response && error.response.data) {
          if (error.response.data.response === "Incorect Hash") {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "Your session has expired",
            });
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: "",
            });
            dispatch(logout());
          } else {
            dispatch({
              type: CHANGE_ERROR_MESSAGE,
              payload: error.response.data.response,
            });
          }
          dispatch(handleAuthLoading(false));
        }
      });
  };
}

export function updateClientEmail(clientEmail, id) {
  return (dispatch) => {
    dispatch(handleLoadState(true));
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      updateTransactionEmail: "",
      hash: hashToken,
      clientEmail,
      urlUsed: currentUrl,
      transactionId: id,
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );
    request
      .then((response) => {
        dispatch({
          type: UPDATE_CLIENT_EMAIL,
          payload: response.data.response,
        });
        setTimeout(() => {
          dispatch({
            type: UPDATE_CLIENT_EMAIL,
            payload: "",
          });
        }, 4000);
        dispatch(handleLoadState(false));
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
}


export function updateBps(bps) {
  return (dispatch) => {
    let hashToken = localStorage.getItem("hash");
    const currentUrl = window.location.href;

    let payload = {
      updateBps: "",
      volume: bps,
      urlUsed: currentUrl,
      hash: hashToken
    };

    const request = axios.post(
      "https://ppapi.instaswap.com/formData",
      payload
    );

    request
      .then((response) => {
        dispatch({
          type: UPDATE_BPS,
          payload: bps
        });
      })
      .catch((error) => {
        console.error('Error updating bps:', error);
      });
  };
}

export function getQuotes(sumBalanceInUsd) {
  return async (dispatch) => {
    if (sumBalanceInUsd > 100) {
      try {
        const usdcQuote = await axios.get(`https://api.instaswap.com/v2/full/aggregator/quote?sellAsset=ETH.USDC-0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&buyAsset=BTC.BTC&slippage=3&sellAmount=${sumBalanceInUsd}`);

        const payload = {
          usdc: usdcQuote.data.response,
        };

        dispatch({
          type: GET_QUOTES,
          payload,
        });

        return payload; // Return the payload
      } catch (error) {
        console.error("Failed to fetch quotes", error);
      }
    }
  };
}