import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-date-fns';

const TransactionsChart = () => {
  const [labels, setLabels] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [dailyProfits, setDailyProfits] = useState([]);
  const [volumeData, setVolumeData] = useState([]);

  const currentMonthData = useSelector(
    (state) => state.userReducer.transactionsChartData.currentMonth
  );

  useEffect(() => {
    if (currentMonthData && Object.keys(currentMonthData).length > 0) {
      const totalOrders = [];
      const completedOrders = [];
      const profits = [];
      const volumes = [];
      const chartLabels = [];

      Object.keys(currentMonthData).forEach((key) => {
        const dayData = currentMonthData[key];
        if (typeof dayData === "object" && dayData && dayData.day) {
          const dateLabel = new Date(dayData.year, dayData.month - 1, dayData.day); // Create a proper date object
          chartLabels.push(dateLabel);
          totalOrders.push(dayData.sumOrders || 0);
          completedOrders.push(dayData.completedOrders || 0);
          profits.push(dayData.eurProfit || 0);
          volumes.push(dayData.volume || 0);
        }
      });

      setTotalTransactions(totalOrders);
      setCompletedTransactions(completedOrders);
      setDailyProfits(profits);
      setVolumeData(volumes);
      setLabels(chartLabels);
    }
  }, [currentMonthData]);

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Swaps",
        data: totalTransactions,
        backgroundColor: "#1d1d1d",
        borderColor: "#1d1d1d",
        borderWidth: 1,
        barThickness: 24,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
      {
        type: "bar",
        label: "$ Volume",
        data: volumeData,
        yAxisID: "y3",
        backgroundColor: "#374151",
        borderColor: "#374151",
        borderWidth: 1,
        barThickness: 10,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
      },
      {
        type: "line",
        label: "Complete Swaps",
        data: completedTransactions,
        yAxisID: "y1",
        borderColor: "#0caa71",
        backgroundColor: "#0caa7160",
        borderWidth: 0,
        fill: true,
        tension: 0.4,
        pointRadius: 0, // Remove dots
      },
      {
        type: "line",
        label: "$ Profit",
        data: dailyProfits,
        yAxisID: "y2",
        borderColor: "#181818",
        backgroundColor: "transparent",
        borderWidth: 1.5,
        pointStyle: "triangle",
        pointRadius: 0, // Remove dots
        pointBackgroundColor: "#ffeff6",
        pointBorderColor: "#181818",
        tension: 0.4,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: 'dd',
          },
          tooltipFormat: "dd/MM/yyyy",
        },
        grid: { display: false },
        ticks: {
          color: "#d5d5d5",
        },
        title: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          color: "#d5d5d5",
          stepSize: 15,
        },
        title: {
          display: false,
        },
      },
      y1: {
        display: false,
      },
      y2: {
        display: false,
      },
      y3: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#d5d5d5",
        },
      },
      tooltip: {
        mode: "index", // show all data for that date
        intersect: false, // ensure all points are shown even when not directly intersecting
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.formattedValue} ${tooltipItem.dataset.label}`;
          },
        },
        backgroundColor: "#1d1d1d",
        titleFont: {
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          size: 12,
        },
        footerFont: {
          size: 12,
        },
        footerColor: "#ffffff",
        borderColor: "#ffffff",
        borderWidth: 1,
        cornerRadius: 5,
        displayColors: false,
      },
    },
  };

  return (
    <div className="w-full relative">
      {data && options && <Chart type="bar" data={data} options={options} />}
    </div>
  );
};

export default TransactionsChart;
