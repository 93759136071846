import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/ISPartnersLogo.png";
import {
    Sidebar,
    Menu,
    MenuItem,
    SubMenu,
} from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faRetweet,
    faUniversity,
    faCode,
    faSignOutAlt,
    faCogs,
    faHeart,
    faCoins,
    faWindowRestore,
    faLink,
    faChartArea,
    faWallet,
    faUserShield,
    faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { changePage, logout } from "../../store/actions";
import '../../scss/_sidebar.scss';
import useWindowSize from "../../utils/useWindowSize"; // Import the CSS file
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const MySidebar = ({ toggled, handleToggleSidebar }) => {
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current location
    const [hover, setHover] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const sized = useWindowSize();
    const currentVersion = "1.1.0";

    // Check version and clear site data if necessary
    const storedVersion = localStorage.getItem("dashboardVersion");
    if (storedVersion !== currentVersion) {
        //  localStorage.clear();
        //  localStorage.setItem("dashboardVersion", currentVersion);
        // window.location.reload();
    }

    const handleLogout = () => {
        localStorage.removeItem("expirationTime");
        localStorage.clear();
        window.location.reload();
        setTimeout(() => {
            dispatch(logout());
        }, 1000);
    };

    const handleChangePage = (value) => {
        dispatch(changePage(value));
    };

    // Function to check if a route is active
    const isActive = (path) => location.pathname === path;

    // Check if the current path is a subpage of settings
    useEffect(() => {
        const settingsPaths = [
            "/whitelisted-ips",
            "/whitelisted-websites",
            "/fee-management",
            "/edit-wallet",
            "/change-password"
        ];
        console.log(location.pathname);
        if (settingsPaths.includes(location.pathname)) {
            setSettingsOpen(true);
        }
    }, [location.pathname]);

    return (
        <>
            {sized.width < 769 ? (
                <div className="form-content-left">
                    <Sidebar
                        toggled={toggled}
                        breakPoint="md"
                        onToggle={handleToggleSidebar}
                        backgroundColor="#2d2d2d"
                        rootStyles={{
                            color: "#fff",
                            borderRight: "none",
                        }}
                    >
                        <div
                            style={{
                                padding: "24px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: "1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginTop: "60px"
                            }}
                            className="uppercase text-grey p-2 mx-2 text-3xl hover:text-blue-light"
                        >
                            <img src={logo} alt="logo" />
                        </div>

                        <Menu
                            iconShape="circle"
                            menuItemStyles={{
                                button: {
                                    "&:hover": {
                                        backgroundColor: "#5d5d5d",
                                    },
                                },
                                subMenuContent: {
                                    backgroundColor: "#3d3d3d",
                                },
                            }}
                        >
                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "25px" }}>Main</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faHome} />}
                                component={<Link to="/overview" />}
                                onClick={() => {
                                    handleChangePage("overview");
                                    handleToggleSidebar();
                                }}
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faRetweet} />}
                                component={<Link to="/transactions" />}
                                onClick={() => {
                                    handleChangePage("transactions");
                                    handleToggleSidebar();
                                }}
                            >
                                Swap History
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUniversity} />}
                                component={<Link to="/balances" />}
                                onClick={() => {
                                    handleChangePage("balances");
                                    handleToggleSidebar();
                                }}
                            >
                                Balances
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCoins} />}
                                component={<Link to="/available-coins-pairs" />}
                                onClick={() => {
                                    handleChangePage("available-coins-pairs");
                                    handleToggleSidebar();
                                }}
                            >
                                Available Coins / Pairs
                            </MenuItem>

                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "30px", marginTop: "30px" }}>Resources</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                            >
                                API
                            </MenuItem>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                            >
                                Widget
                            </MenuItem>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                            >
                                Referral
                            </MenuItem>

                            <SubMenu
                                label="Whitelisting"
                                icon={<FontAwesomeIcon icon={faCogs} />}
                                style={{ backgroundColor: hover ? "#5d5d5d" : "#2d2d2d" }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                defaultOpen={settingsOpen}
                                open={settingsOpen}
                                onOpenChange={() => setSettingsOpen(!settingsOpen)}
                            >

                                <MenuItem
                                    component={<Link to="/whitelisted-ips" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-ips");
                                        handleToggleSidebar();
                                    }}
                                >
                                    API IPs
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/whitelisted-websites" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-websites");
                                        handleToggleSidebar();
                                    }}
                                >
                                    Widget URLs
                                </MenuItem>
                            </SubMenu>

                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "30px", marginTop: "30px" }}>Management</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                                component={<Link to="/fee-management" />}
                                onClick={() => {
                                    handleChangePage("fee-management");
                                    handleToggleSidebar();
                                }}
                            >
                                Partner Fee
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faWallet} />}
                                component={<Link to="/edit-wallet" />}
                                onClick={() => {
                                    handleChangePage("edit-wallet");
                                    handleToggleSidebar();
                                }}
                            >
                                Funding Wallet
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUserShield} />}
                                component={<Link to="/change-password" />}
                                onClick={() => {
                                    handleChangePage("change-password");
                                    handleToggleSidebar();
                                }}
                            >
                                Account
                            </MenuItem>
                            <MenuItem
                                onClick={() => window.open('https://discord.gg/um6NazJSMk', '_blank')}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <FontAwesomeIcon icon={faDiscord} style={{ marginRight: "18px", marginLeft: "5px" }} />
                                <span>Discord</span>
                            </MenuItem>
                        </Menu>

                        <div
                            className="wrap sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                            }}
                        >
                            <button
                                style={{ width: "90%" }}
                                className="sidebar-btn logout"
                                rel="noopener noreferrer"
                                onClick={handleLogout}
                            >
                                <FontAwesomeIcon color="red" icon={faSignOutAlt} />
                                <span>Logout</span>
                            </button>
                        </div>
                        <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                                color: "white",
                                fontSize: '10px'
                            }}
                        >
                            Dashboard v{currentVersion}
                        </div>

                    </Sidebar>
                </div >
            ) : (
                <div className="form-content-left">
                    <Sidebar
                        toggled={toggled}
                        breakPoint="md"
                        onToggle={handleToggleSidebar}
                        backgroundColor="#2d2d2d"
                        rootStyles={{
                            color: "#fff",
                            borderRight: "none",
                            height: '100%',
                        }}
                    >
                        <div
                            style={{
                                padding: "24px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                fontSize: 14,
                                letterSpacing: "1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            className="uppercase text-grey p-2 mx-2 text-3xl hover:text-blue-light"
                        >
                            <img src={logo} alt="logo" />
                        </div>

                        <Menu
                            iconShape="circle"
                            menuItemStyles={{
                                button: {
                                    "&:hover": {
                                        backgroundColor: "#5d5d5d",
                                    },
                                },
                                subMenuContent: {
                                    backgroundColor: "#3d3d3d",
                                },
                            }}
                            style={{ padding: '10px' }}
                        >
                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "25px" }}>Main</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faChartArea} />}
                                component={<Link to="/overview" />}
                                onClick={() => {
                                    handleChangePage("overview");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/overview")}
                                style={{
                                    backgroundColor: isActive("/overview") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faRetweet} />}
                                component={<Link to="/transactions" />}
                                onClick={() => {
                                    handleChangePage("transactions");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/transactions")}
                                style={{
                                    backgroundColor: isActive("/transactions") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Swap History
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUniversity} />}
                                component={<Link to="/balances" />}
                                onClick={() => {
                                    handleChangePage("balances");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/balances")}
                                style={{
                                    backgroundColor: isActive("/balances") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Balances
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCoins} />}
                                component={<Link to="/available-coins-pairs" />}
                                onClick={() => {
                                    handleChangePage("available-coins-pairs");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/available-coins-pairs")}
                                style={{
                                    backgroundColor: isActive("/available-coins-pairs") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Available Networks
                            </MenuItem>


                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "30px", marginTop: "30px" }}>Resources</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faCode} />}
                                component={<Link to="/api-reference" />}
                                onClick={() => {
                                    handleChangePage("api-reference");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/api-reference")}
                                style={{
                                    backgroundColor: isActive("/api-reference") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                API
                            </MenuItem>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faWindowRestore} />}
                                component={<Link to="/widget" />}
                                onClick={() => {
                                    handleChangePage("widget");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/widget")}
                                style={{
                                    backgroundColor: isActive("/widget") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Widget
                            </MenuItem>
                            <MenuItem
                                icon={<FontAwesomeIcon icon={faLink} />}
                                component={<Link to="/referral" />}
                                onClick={() => {
                                    handleChangePage("referral");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/referal")}
                                style={{
                                    backgroundColor: isActive("/referral") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Referral
                            </MenuItem>

                            <SubMenu
                                label="Whitelisting"
                                icon={<FontAwesomeIcon icon={faCogs} />}
                                style={{ backgroundColor: hover ? "#5d5d5d" : "#2d2d2d" }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                defaultOpen={settingsOpen}
                                open={settingsOpen}
                                onOpenChange={() => setSettingsOpen(!settingsOpen)}
                            >


                                <MenuItem
                                    component={<Link to="/whitelisted-ips" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-ips");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/whitelisted-ips")}
                                    style={{
                                        backgroundColor: isActive("/whitelisted-ips") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    API IPs
                                </MenuItem>

                                <MenuItem
                                    component={<Link to="/whitelisted-websites" />}
                                    onClick={() => {
                                        handleChangePage("whitelisted-websites");
                                        handleToggleSidebar();
                                    }}
                                    active={isActive("/whitelisted-websites")}
                                    style={{
                                        backgroundColor: isActive("/whitelisted-websites") ? "#1d1d1d" : "inherit"
                                    }}
                                >
                                    Widget URLs
                                </MenuItem>
                            </SubMenu>


                            <div className="menu-title" style={{ fontSize: '12px', color: "darkgrey", marginLeft: "30px", marginTop: "30px" }}>Management</div>
                            <div className="menu-divider" style={{ borderBottom: "1px solid #5d5d5d", margin: "10px 0" }}></div>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                                component={<Link to="/fee-management" />}
                                onClick={() => {
                                    handleChangePage("fee-management");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/fee-management")}
                                style={{
                                    backgroundColor: isActive("/fee-management") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Partner Fee
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faWallet} />}
                                component={<Link to="/edit-wallet" />}
                                onClick={() => {
                                    handleChangePage("edit-wallet");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/edit-wallet")}
                                style={{
                                    backgroundColor: isActive("/edit-wallet") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Funding Wallet
                            </MenuItem>

                            <MenuItem
                                icon={<FontAwesomeIcon icon={faUserShield} />}
                                component={<Link to="/change-password" />}
                                onClick={() => {
                                    handleChangePage("change-password");
                                    handleToggleSidebar();
                                }}
                                active={isActive("/change-password")}
                                style={{
                                    backgroundColor: isActive("/change-password") ? "#1d1d1d" : "inherit"
                                }}
                            >
                                Account
                            </MenuItem>
                            <MenuItem
                                onClick={() => window.open('https://discord.gg/um6NazJSMk', '_blank')}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <FontAwesomeIcon icon={faDiscord} style={{ marginRight: "18px", marginLeft: "5px" }} />
                                <span>Discord</span>
                            </MenuItem>
                        </Menu>
                    </Sidebar>
                    <div style={{ width: '100%', backgroundColor: '#2d2d2d' }}>
                        <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                            }}
                        >
                            <button
                                style={{ width: "90%" }}
                                className="sidebar-btn logout"
                                rel="noopener noreferrer"
                                onClick={handleLogout}
                            >
                                <FontAwesomeIcon color="red" icon={faSignOutAlt} />
                                <span>Logout</span>
                            </button>
                        </div>
                        <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: "10px 14px",
                                color: "white",
                                fontSize: '10px'
                            }}
                        >
                            Dashboard v{currentVersion}
                        </div>

                    </div>
                </div>
            )}
        </>
    );
};

export default MySidebar;
