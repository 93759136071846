import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import "../scss/index.scss";
import {apiRequestLimits, changeWallet, getActiveWallet} from "../store/actions";
import DataLoader from "../components/globalView/DataLoader";
import { Helmet } from "react-helmet";

const EditWallet = () => {
  const dispatch = useDispatch();

  const [wallet, setWallet] = useState();
     const [successMessage, setSuccessMessage] = useState(false);
  const [showUpdateDiv, setShowUpdateDiv] = useState(false);

  useEffect(() => {
    dispatch(getActiveWallet());
  }, [dispatch]);

  const showDiv = () => {
    setShowUpdateDiv(true);
  };
  const hideDiv = () => {
    setShowUpdateDiv(false);
  };
  const handleSubmit = () => {
    dispatch(changeWallet(wallet));
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
      setShowUpdateDiv(false);
    }, 1500);
  };
  const isLoading = useSelector((state) => state.userReducer.loading);
  const activeWallet = useSelector((state) => state.userReducer.activeWallet);

  useEffect(() => {
    dispatch(apiRequestLimits());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        {" "}
        <title>Wallet Management | Instaswap Partners</title>{" "}
      </Helmet>
      {isLoading ? (
        <DataLoader />
      ) : (
          <div className="px-0 main-area extraMn">
            <div className="flex md:flex-row flex-col justify-between items-center px-2">
              <div className="flex flex-col xl:w-3/12 md:w-6/12 w-full">
                <h1 className="text-4xl w-full text-left text-white">Edit Funding wallet</h1>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start h-2/4 mt-20">
            {!showUpdateDiv && (
              <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
                <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                 <img style={{width:'150px', marginTop:'30px'}} src='https://web.instaswap.com/images/products/large-389ee44692b3dc76b3a1b32d537b260a.png' alt=""></img>
                  {activeWallet && (
                     <div>
                       <h2 className="text-gray-200 ml-3">Active Withdraw Wallet:</h2>
                       <p className="font-semibold  text-white p-2 rounded-md flex items-center mt-5 mb-5">
                         &nbsp;
                         {activeWallet}
                       </p>
                       <div style={{color:'white', padding:'15px'}}>NOTICE: Please be careful when submitting a withdrawal request, as the funds will be sent to the wallet specified.</div>
                     </div>
                  )}
                </div>
                {!activeWallet && (
                    <button
                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                        style={{ background: "#23ac79" }}
                        onClick={showDiv}
                    >
                      Set up a withdraw wallet
                    </button>
                )}
                {activeWallet && (
                    <button
                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                        style={{ background: "#23ac79" }}
                        onClick={showDiv}
                    >
                      Change Wallet
                    </button>
                )}
              </div>
            )}

            {showUpdateDiv && (
              <div className="xl:w-4/12 w-10/12 xl:p-10 customCont">
                <div className="flex flex-col md:flex-column items-center justify-center gap-4">
                  <img style={{width:'150px'}} src='https://web.instaswap.com/images/products/large-389ee44692b3dc76b3a1b32d537b260a.png' alt=""></img>
                  <p className="text-gray-200  mb-6 text-center">
                    Please be careful with the wallet address. Ensure it is correct, as the funds will be lost if the withdrawal is sent to the wrong address..
                  </p>
                  <div className="flex items-center justify-center gap-4 w-full">
                    <p className="text-gray-200 flex items-center">
                      {" "}
                      Wallet:
                    </p>
                    <input
                        className="form-input"
                        type="text"
                        name="name"
                        value={wallet}
                        required
                        onChange={(e) => setWallet(e.target.value)}
                    />
                  </div>
                  <div className="flex w-full gap-4">
                    <button
                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                        style={{ background: "#23ac79" }}
                        onClick={handleSubmit}
                    >
                      {activeWallet ? (
                          isLoading ? (
                              <FontAwesomeIcon color="white" icon={faSpinner} spin />
                          ) : (
                              <span>Update Wallet</span>
                          )
                      ) : (
                          isLoading ? (
                              <FontAwesomeIcon color="white" icon={faSpinner} spin />
                          ) : (
                              <span>Save Wallet</span>
                          )
                      )}

                    </button>
                    <button
                        className="w-full p-2 text-gray-200 rounded-md mt-6"
                        style={{ background: "#F0544E" }}
                        onClick={hideDiv}
                    >
                      Cancel
                    </button>
                  </div>
                  {successMessage && (
                      <div className="text-green-500 bg-gray-200 w-full text-center p-2 mt-2">
                        Your wallet saved successfully
                      </div>
                  )}
                </div>
                </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EditWallet;
