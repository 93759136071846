import React from "react";
import Loader from "../../images/instaswapLoaderIcon.png";

const DataLoader = (props) => {
  return (
    <div className="bull-loader-wrapper">
      <img
        src={Loader}
        alt="instaswap logo loader"
        style={{ width: "100px", height: 'auto' }}
      />
    </div>
  );
};

export default DataLoader;
